import getDiffObjects from 'utils/getDiffObjects';
import usePrevious from './usePrevious';
import useDidUpdate from './useDidUpdate';

const useChangeEffect = (callback, dependencies = []) => {
  const prevDeps = usePrevious(dependencies);

  useDidUpdate(() => {
    const changedDeps = dependencies.map((dep, index) => {
      return getDiffObjects(dep, prevDeps?.[index] || {});
    })

    callback(changedDeps.map((dep) => ({ key: dep[0], value: dep[1] })));
  }, dependencies);
}

export default useChangeEffect;

