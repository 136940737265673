import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { usePopperTooltip } from 'react-popper-tooltip';

import ICONS from 'assets/icons';
import { BOOKING_STATUSES } from 'redux/ducks/sessions';
import { BOOKING_STATUS_FILTERS } from 'config/constants';
import { BOOKING_ITEM_DATE_CONFIG } from 'config/dates-сonfig';
import {
  classModifier,
  getContactAvatar,
  getDateByTimezoneOffset,
} from 'utils';
import { 
  selectLockedOperator, 
  selectUserTimezone,
  selectBookingsByBookingIdFromSessions,
  selectBookingById
 } from 'redux/selectors/selectors';
import { MODAL_TYPES, openModal } from 'redux/ducks/activeWindows';

import './BookingItem.scss';
import Portal from 'components/Portal';
import DateTime from 'components/DateTime';
import LazyLoadImage from '../LazyLoadImage/LazyLoadImage';
import BookingConfirmationStatus from 'components/BookingConfirmationStatus/BookingComfirmationStatus';
import { getContactsById } from '../../redux/ducks/contacts';
import { updateActiveContact } from '../../redux/ducks/clientChats';

const BookingItem = (props) => {
  const {
    item,
    sessionId,
    hourMarker,
    dateMarker,
    setBookings,
    isTwoDaysPage,
    isScrollToDate,
    isSalesSession,
    classNameModifier,
    isPrebookingsList,
    isBookingByDateModal,
    showHourMarker = false,
    showFullClientInfo = false,
    onDeleteAction,
    refs = {},
  } = props;

  const [dataBI, setDataBI] = useState(0);

  const {
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip({
    trigger: 'hover',
    placement: 'top',
  });

  const dispatch = useDispatch();
  const userTimezone = useSelector(selectUserTimezone);

  useEffect(() => {
    let pickedDateByTimezone = 0;

    if (Array.isArray(booking.date)) {
      pickedDateByTimezone = getDateByTimezoneOffset(userTimezone, booking.date[0]);
    } else {
      pickedDateByTimezone = getDateByTimezoneOffset(userTimezone, booking.date);
    }

    setDataBI(+pickedDateByTimezone);
  }, []);

  const booking = isPrebookingsList ? item : useSelector((state) =>
    !isSalesSession
      ? selectBookingById(state, props.booking.id)
      : selectBookingsByBookingIdFromSessions(state, sessionId, item)
  );

  const bookedDivaSoloProfile = useSelector(state => state.divaGirls.entities[booking?.profile_id]);

  const operatorName = useSelector((state) =>
    selectLockedOperator(state, booking.userId || booking.usersIds?.[0] || +booking.users_ids));

  const profilesNamesData = (typeof booking.group_girls === 'string' 
    ? JSON.parse(booking.group_girls) 
    : booking.group_girls) || {};
  const { profile_id, profile_id_duo } = booking;

  const girlsCallerIds = (booking.girls_caller_ids && JSON.parse(booking.girls_caller_ids)) || {};
  const soloProfileContactId = +girlsCallerIds[profile_id];
  const duoProfileContactId = +girlsCallerIds[profile_id_duo];

  const bookingStatusColor
    = Object.values(BOOKING_STATUS_FILTERS)
            .find((status) => status.label === booking.confirmation_status).color;
  const [soloGirlGroupObject, duoGirlGroupObject] = booking.groupGirls || [];
  const soloProfileName = booking.profile_name || booking.profileName || soloGirlGroupObject?.profileName || bookedDivaSoloProfile?.name || profilesNamesData[profile_id];
  const duoProfileName = profilesNamesData[profile_id_duo];
  const isDuo = booking.meeting_type === 'duo';

  const handleOpenEditBooking = () => {
    if(isPrebookingsList) {
      return;
    };

    const props = {
      booking,
      profileId: booking.profile_id,
      callerId: booking.callerId,
    };

    if (setBookings) props.updateBookingsList = setBookings;

    dispatch(openModal(MODAL_TYPES.bookingEditForm, props))
      .then((res) => {
        if (res?.isDeleted && onDeleteAction) {
          onDeleteAction();
        }
      });
  };

  const handleOpenContactEditor = id => {
    if (isPrebookingsList && id) {
      dispatch(getContactsById(id, 2))
        .then((contact) => {
          if (contact) {
            dispatch(openModal(MODAL_TYPES.contactCard, { contact: id }));
          }
        })
    }
  }

  const renderRequirements = () => {
    const getBookingRequirementsIndicator = (requirement) => (
      <span
        key={requirement.id}
        className="booking-item__requirements-indicator"
        style={{ backgroundColor: requirement.color }}
      />
    );

    const currentRequirementsColors = booking.requirementsColors || 
      (typeof booking.requirements === 'string' && booking.requirements.split(','))

    return (
      <div className="booking-item__column">
        <div className="booking-item__requirements-indicator-box">
          {Array.isArray(booking.requirements) && !!booking.requirements.length && (
            booking.requirements.map(
              (requirement) => (
                !requirement.isChecked && getBookingRequirementsIndicator(requirement))
            ))}

          {currentRequirementsColors && currentRequirementsColors.map(color => {
            return (
              <span
                key={color}
                className="booking-item__requirements-indicator"
                style={{ backgroundColor: color }}
              />
            )
          }).reverse()}
        </div>
      </div>
    );
  };

  return (
    <li
      key={booking.id}
      className={classModifier('booking-item', [
        showHourMarker && 'displayed-with-marker',
        dateMarker && 'displayed-with-date-marker',
        classNameModifier && classNameModifier,
        isPrebookingsList && 'is-prebooking'
      ])}
      data-booking-item={dataBI}
      onClick={handleOpenEditBooking}
      ref={(node) => refs[booking.id] = node}
    >
      {dateMarker && (
        <div className="booking-item__date-marker">
          {dateMarker}
        </div>
      )}

      {showHourMarker && hourMarker && (
        <div 
          data-hour-marker
          className="booking-item__hour-marker"
        >
          {hourMarker}
        </div>
      )}

      <div
        className="booking-item__color-line"
        style={{ backgroundColor: bookingStatusColor }}
      />

      <div className="booking-item__column">
        <p 
          onClick={() => handleOpenContactEditor(soloProfileContactId)}
          className={classModifier("booking-item__name", isPrebookingsList && 'is-prebooking')}
        >
          {soloProfileName}
        </p>
        {(duoGirlGroupObject || duoProfileName) && 
         <p 
            onClick={() => handleOpenContactEditor(duoProfileContactId)}
            className={classModifier("booking-item__name", 'duo')}
          >
            {duoProfileName || duoGirlGroupObject.profileName || booking.duoProfileName}
          </p>
        }
      </div>

      <div className="booking-item__column">
        <div className={classModifier('booking-item__badge', [isDuo && 'duo'])}>
          {isDuo && 'DUO'}
        </div>
      </div>

      {isTwoDaysPage && (
        <div className="booking-item__column">
          <div className="booking-item__client">
            <BookingItemClientName clientName={booking.caller.fn} />
          </div>
        </div>
      )}

      <div className="booking-item__column">
        {isTwoDaysPage && (
          <p className="booking-item__date">
            <DateTime
              date={booking.date}
              config={BOOKING_ITEM_DATE_CONFIG}
              isScrollToDate={isScrollToDate}
            />
          </p>
        )}

        <p className={classModifier("booking-item__price", isPrebookingsList && 'is-prebooking')}>
          {booking.duration} /&nbsp;<span className="booking-item__type">{booking.type}</span>&nbsp;/ £{booking.rate}
        </p>
      </div>

      {!isTwoDaysPage && renderRequirements()}

      {!isBookingByDateModal && (
        <div className={classModifier('booking-item__column', 'grow')}>
          <p
            className={classModifier('booking-item__text-status', [
              BOOKING_STATUSES[booking.status].replace(/ /g, '-'),
            ])}
          >
            {BOOKING_STATUSES[booking.status]}
          </p>

          {!isTwoDaysPage && (
            <p className="booking-item__date">
              <DateTime
                isScrollToDate={isScrollToDate}
                date={booking.date}
                config={BOOKING_ITEM_DATE_CONFIG}
              />
            </p>
          )}

          {isTwoDaysPage && renderRequirements()}
        </div>
      )}

      {showFullClientInfo && (
        <div className={classModifier('booking-item__column', 'grow')}>
          <div className="booking-item__client-wrap">
            <div className="booking-item__client">
              <LazyLoadImage
                alt="ava"
                src={getContactAvatar(booking.caller)}
                className="booking-item__client-avatar"
              />

              <BookingItemClientName clientName={booking.caller.fn} />
            </div>
          </div>
        </div>
      )}

      <div
        className={classModifier('booking-item__column', [
          isBookingByDateModal && 'grow',
        ])}
      >
        <div
          className={classModifier('booking-item__info-wrap', [
            isBookingByDateModal && 'booking-modal',
          ])}
        >
          {isBookingByDateModal && (
            <p
              className={classModifier('booking-item__text-status', [
                BOOKING_STATUSES[booking.status].replace(/ /g, '-'),
              ])}
            >
              {BOOKING_STATUSES[booking.status]}
            </p>
          )}

          <div className="booking-item__statuses-wrap">
            <div className="booking-item__icons-list">
              {!!booking.is_prebooking && (
                <>
                  <ICONS.p
                    className={classModifier('booking-item__icons-list-item', [
                      'prebooking'
                    ])}
                    ref={setTriggerRef}
                  />

                  {visible && (
                    <Portal>
                      <div className='popup' ref={setTooltipRef} {...getTooltipProps()}>
                        prebooking
                      </div>
                    </Portal>
                  )}
                </>
              )}
            </div>

            <BookingConfirmationStatus
              status={booking.confirmation_status}
              reason={booking.reason}
              description={booking.description}
            />
          </div>
        </div>

        <div
          className={classModifier('booking-item__info-wrap', [
            isBookingByDateModal && 'booking-modal',
          ])}
        >
          {isBookingByDateModal && (
            <BookingItemClientName clientName={booking.caller.fn} />
          )}

          <p className="booking-item__operator">
            {operatorName}
          </p>
        </div>
      </div>
    </li>
  );
};

const BookingItemClientName = ({ clientName }) => {
  const {
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip({
    trigger: 'hover',
    placement: 'top-start',
  });

  return (
    <>
      <div
        className="booking-item__client-name"
        ref={setTriggerRef}
      >
        {clientName}
      </div>

      {visible && (
        <Portal>
          <div className='popup' ref={setTooltipRef} {...getTooltipProps()}>
            {clientName}
          </div>
        </Portal>
      )}
    </>
  );
};

export default BookingItem;
