
import { useEffect, useRef, useState } from 'react';
import { useSetSocketPageStatus, useToggle } from 'hooks';
import { Allotment } from 'allotment';

import { classModifier } from 'utils';
import useSplitPaneSizes from 'hooks/useSplitPaneSizes';

import './MainPage.scss';
import CallsSection from 'containers/CallsSection/CallsSection';
import SidebarClientsChatList from 'containers/SidebarChatLists/SidebarClientsChatList/SidebarClientsChatList';
import SidebarGirlsChatList from 'containers/SidebarChatLists/SidebarGirlsChatList/SidebarGirlsChatList';
import ClientChat from 'containers/Chat/ClientChat';
import GirlChat from 'containers/Chat/GirlChat';
import OperatorsList from 'components/OperatorsList/OperatorsList';
import PublicChat from 'containers/Chat/PublicChat/PublicChat';
import { useDispatch, useSelector } from 'react-redux';
import { MODAL_TYPES, closeModal, openModal } from 'redux/ducks/activeWindows';
import { SOCKET_PAGES_TITLES } from 'config/constants';

const operatorsListMinHeight = 55;

const LeftSidebar = () => {
  return (
    <div className={classModifier("main-page__sidebar", "left")}>
      <SidebarClientsChatList />
    </div>
  )
}

const RightSidebar = ({ topPane: TopPane, bottomPane: BottomPane }) => {
  const [isOpenned, toggleOpenned] = useToggle(false);
  const allotmentRef = useRef();
  const containerRef = useRef();
  const lastItemRef = useRef();
  const selectorRef = useRef();

  const handleClick = (event) => {
    const containerHeight = containerRef.current.offsetHeight;

    if (isOpenned) {
      // Resize the bottom pane to the minimum height
      allotmentRef.current.resize([containerHeight - operatorsListMinHeight, operatorsListMinHeight]);
    } else {
      // If there are no operators, scroll to the selector
      const slideToTargetRef = lastItemRef.current ? lastItemRef : selectorRef;
  
      // Get the position of the scrolled and clicked items
      const targetRef = slideToTargetRef.current.getBoundingClientRect();
      const clickRect = event.currentTarget.getBoundingClientRect();
  
      // Calculate the distance between the two elements
      const distance = Math.abs(targetRef.bottom - clickRect.top)
        + 5 // padding offset of allotment slider
        + targetRef.height / 2; // by some reason rect object gives center of the element but not bottom, so we need to add half of the height
  
      allotmentRef.current.resize([containerHeight - distance, distance]);
    }

    toggleOpenned();
  }

  const handleResize = ([_, bottomPanelSize]) => {
    toggleOpenned(bottomPanelSize !== operatorsListMinHeight);
  }

  return (
    <div className="main-page__sidebar" ref={containerRef}>
      <Allotment
        ref={allotmentRef}
        defaultSizes={['fill', 10]}
        onDragEnd={handleResize}
        vertical
      >
        <Allotment.Pane minSize={250}>
          <TopPane />
        </Allotment.Pane>

        <Allotment.Pane minSize={55}>
          <BottomPane
            lastItemRef={lastItemRef}
            selectorRef={selectorRef}
            handleHeaderClick={handleClick}
            toggleOpenned={toggleOpenned}
          />
        </Allotment.Pane>
      </Allotment>
    </div>
  )
}

const MainSection = () => {
  const [notForClients, setNotForClients] = useState([]);

  return (
    <div className="main-page__chats">
      <ClientChat
        title="Conversations with Clients"
        setNotForClients={setNotForClients}
      />

      <PublicChat />

      <GirlChat
        title="Conversations with Girls"
        notForClients={notForClients}
      />
    </div>
  )
}

const MainPage = () => {
  const [isShowCallSection, toggleCallSection] = useToggle(false);

  const main = useSplitPaneSizes('main');

  useSetSocketPageStatus(SOCKET_PAGES_TITLES.main);

  const mainPageOpenIds = useSelector((state) => state.user.pages?.[SOCKET_PAGES_TITLES.main]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (mainPageOpenIds?.length > 1) {
      dispatch(openModal(MODAL_TYPES.openCommModule, {
        title: "Comm module is already open in other tab",
        isNeedButton: false,
      }));
    } else if (mainPageOpenIds?.length < 2) {
      dispatch(closeModal(MODAL_TYPES.openCommModule, false));
    }
  }, [mainPageOpenIds?.length])

  return (
    <main className="main-page">
      <div
        className={classModifier("main-page__calls", [isShowCallSection && "show"])}
      >
        <CallsSection
          isShowCallSection={isShowCallSection}
          toggleCallSection={toggleCallSection}
        />
      </div>

      <div className="main-page__main">
        <Allotment
          defaultSizes={main.defaultSizes}
          onChange={main.onDragEnd}
        >
          <Allotment.Pane minSize={282} maxSize={400}>
            <LeftSidebar />
          </Allotment.Pane>

          <Allotment.Pane minSize={940}>
            <MainSection />
          </Allotment.Pane>

          <Allotment.Pane minSize={282} maxSize={400}>
            <RightSidebar
              classPrefix="operators"
              topPane={SidebarGirlsChatList}
              bottomPane={OperatorsList}
            />
          </Allotment.Pane>
        </Allotment>
      </div>
    </main>
  );
};

export default MainPage;
