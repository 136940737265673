import React from "react";
import { usePopperTooltip } from "react-popper-tooltip";

import ICONS from "assets/icons";
import { DIVA_GIRLS_LINK } from "redux/ducks/divaGirls";

import ProfileRates from "components/ProfileRates/ProfileRates";

const ProfileCardHeader = (props) => {
  const { profile } = props;

  const {
    visible,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef
  } = usePopperTooltip({
    offset: [0, 0],
    trigger: "hover",
    placement: 'auto',
  });

  return (
    <div className="profile-card__header">
      {profile.link &&
        <a
          href={DIVA_GIRLS_LINK + profile.link}
          target="_blank"
          className="profile-card__header-btn"
        >
          <ICONS.externalLinkSquare width={14} height={14} />
        </a>
      }

      <div 
        ref={setTriggerRef}
        className="profile-card__header-btn"
      >
        <ICONS.poundSign className="profile-card__header-btn-icon" width={13} height={13} />

        {visible &&
          <div 
            ref={setTooltipRef} 
            className="profile-card__header-rates" 
            {...getTooltipProps()}
          >
            <ProfileRates
              isEditor
              profileId={profile.id}
              isMember={profile.is_for_member || false}
              defaultPrices={profile.prices || []}
              memberPrices={profile.prices_member || []}
              secretPrices={profile.prices_secret || []}
            />
          </div>
        }
      </div>
    </div>
  )
};

export default ProfileCardHeader;
