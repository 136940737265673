import React, { useEffect } from 'react';
import { FormSpy } from 'react-final-form';

import { useDeepEffect, usePrevious } from 'hooks';
import { isEmptyObj } from 'utils';
import { CONTACT_TYPES } from 'config/constants';

const isFormEdited = (values, initialValues, dirtyFields, isPhotoFile, isEditedTags) => {
  const isNotEqualObjects = (initialValues, values, fieldName) => {
    if (values?.length !== initialValues?.length) {
      return true;
    }
    switch (fieldName) {
      case 'tels': {
        return values?.some((obj, idx) => (
          obj.tel !== initialValues[idx].tel || 
          obj.uuid !== initialValues[idx].uuid
        ))
      }

      case 'emails': {
        return values?.some((obj, idx) => (
          obj.email !== initialValues[idx].email ||
          obj.uuid !== initialValues[idx].uuid
        ))
      }

      case 'telegram_nicknames': {
        return values?.some((obj, idx) => (
          obj.nickname !== initialValues[idx].nickname
        ))
      }

      case 'urls': {
        return values?.some((obj, idx) => (
          obj.url !== initialValues[idx].url ||
          obj.title !== initialValues[idx].title
        ))
      }

      case 'addresses': {
        return values?.some((obj, idx) => (
          obj.caller_id !== initialValues[idx].caller_id ||
          obj.line1 !== initialValues[idx].line1 || 
          obj.line2 !== initialValues[idx].line2 ||
          obj.city !== initialValues[idx].city ||
          obj.postcode !== initialValues[idx].postcode
        ))
      }

      default: return false;
    }
  }

  return (
    isPhotoFile ||
    isEditedTags ||
    (+values.type !== CONTACT_TYPES.CLIENT && values.fn !== initialValues.fn) ||
    values.note !== initialValues.note ||
    (+values.type === CONTACT_TYPES.CLIENT && (values.name !== initialValues.name)) ||
    (+values.type === CONTACT_TYPES.CLIENT && (values.description !== initialValues.description)) ||
    (values.custom_id || null) !== (initialValues.custom_id || null) ||
    Boolean(dirtyFields.type) ||
    Boolean(initialValues.is_ex) !== Boolean(values.is_ex) ||
    initialValues.is_banned_for_media !== values.is_banned_for_media ||
    isNotEqualObjects(initialValues.tels, values.tels, 'tels') ||
    isNotEqualObjects(initialValues.urls, values.urls, 'urls') ||
    isNotEqualObjects(initialValues.telegram_nicknames, values.telegram_nicknames, 'telegram_nicknames') ||
    isNotEqualObjects(initialValues.emails, values.emails?.filter(item => !!item.email), 'emails')
    // isNotEqualObjects(initialValues.addresses || [], values.addresses?.filter(item => !!item.address), 'addresses')
  )
}

const AdrBookContactFormAutoSave = props => {
  const { 
    form,
    active, 
    values,
    invalid,
    dirtyFields,
    isPhotoFile, 
    saveContact,
    callbacksRef,
    isEditedTags,
    lastStateListItem = {},
    isNewContactCreation,
  } = props;
  
  const prev = usePrevious(active);

  const formViaValues = values.via;
  const isAnyFormFieldEdited = !invalid && isFormEdited(
    values, 
    lastStateListItem,
    dirtyFields,
    isPhotoFile,
    isEditedTags
  );

  if(callbacksRef?.current) {
    callbacksRef.current.saveContact = () => saveContact();
  }

  useEffect(() => {
    props.setEdited && props.setEdited(isAnyFormFieldEdited);
  })

  useEffect(() => {
    // Saving contact data in case of phone/email deletion. If it was 
    // previously selected in the "via" field, the form state will be 
    // updated and the save will be performed on the onChange event 
    // for "via"

    const phoneUuid = formViaValues?.via_phone_uuid;
    const smsPhoneUuid = formViaValues?.via_sms_phone_uuid;
    const emailUuid = formViaValues?.via_email_uuid;

    const isDeletedTelsWithVia = values.tels?.some(item => item.uuid === phoneUuid) !==
      lastStateListItem.tels?.some(item => item.uuid === phoneUuid);

    const isDeletedTelsWithSMSVia = values.tels?.some(item => item.uuid === smsPhoneUuid) !==
      lastStateListItem.tels?.some(item => item.uuid === smsPhoneUuid);
      
    const isDeletedEmailsWithVia = values.emails?.some(item => item.uuid === emailUuid) !==
      lastStateListItem.emails?.some(item => item.uuid === emailUuid);

    if (!isNewContactCreation && lastStateListItem.tels?.length > values.tels?.length) {
      if(isDeletedTelsWithVia && isDeletedTelsWithSMSVia) {
        form.change('via.via_phone_uuid', null);
        form.change('via.via_sms_phone_uuid', null);
      } 
      else if(isDeletedTelsWithVia && !isDeletedTelsWithSMSVia) {
        form.change('via.via_phone_uuid', null);
      }
      else if(!isDeletedTelsWithVia && isDeletedTelsWithSMSVia) {
        form.change('via.via_sms_phone_uuid', null);
      }
      else saveContact();
    }

    if (!isNewContactCreation && lastStateListItem.emails?.length > values.emails?.length) {  
      isDeletedEmailsWithVia ? form.change('via.via_email_uuid', null) : saveContact();
    }

    if (!isNewContactCreation && lastStateListItem.telegram_nicknames?.length > values.telegram_nicknames?.length) {  
      saveContact();
    }
  }, [values.tels, values.emails, values.telegram_nicknames]);

  useDeepEffect(() => {
    const initialValuesVia = lastStateListItem?.via;

    const isEditedVia = !!formViaValues?.via_phone_uuid !== !!initialValuesVia?.via_phone_uuid ||
      !!formViaValues?.via_sms_phone_uuid !== !!initialValuesVia?.via_sms_phone_uuid ||
      !!formViaValues?.via_email_uuid !== !!initialValuesVia?.via_email_uuid ||
      !!formViaValues?.via_telegram !== !!initialValuesVia?.via_telegram;

    // condition to save edited fields "via" after getting correct formViaValues
    if (formViaValues) {
      !isNewContactCreation && isEditedVia && saveContact();
    }
  }, [formViaValues]);

  useDeepEffect(() => {
    let isLabelChanged = false;

    //check has label been changed
    for (let [i, tel] of Object.entries(values.tels || {})) {
      if (tel.tel && lastStateListItem?.tels?.[i] && tel.labelName !== lastStateListItem?.tels?.[i]?.labelName) {
        isLabelChanged = true;
        break;
      }
    }

    !isEmptyObj(values) && !isNewContactCreation && isLabelChanged && saveContact();
  }, [values.tels]);

  useEffect(() => {
    // comparing the previous and active field value to simulate the onBlur event

    if ((active || prev) && active !== prev) {
      !isNewContactCreation && isAnyFormFieldEdited && saveContact();
    }

  }, [active]);

  return null;
}

export default props => ( 
  <FormSpy
    {...props}
    subscription={{
      values: true, 
      active: true,
      invalid: true,
      dirtyFields: true,
    }}
    component={AdrBookContactFormAutoSave}
  />
)
