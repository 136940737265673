import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import GoogleMapReact from 'google-map-react';
import useSupercluster from 'use-supercluster';
import { StandaloneSearchBox, useJsApiLoader, GoogleMap } from '@react-google-maps/api';

import API from 'api/api';
import ICONS from 'assets/icons';
import { classModifier, LS } from 'utils';
import { useDeepEffect, useDidMount, useDidUpdate, usePrevious, useToggle } from 'hooks';
import { closeModal, MODAL_TYPES, openModal } from 'redux/ducks/activeWindows';
import { GOOGLE_MAPS_API_KEY, SELECT_THEMES } from 'config/constants';
import { MAP_DEFAULT_OPTIONS, MAP_LIBRARIES, RADIUS_OPTIONS } from './config/config';
import useBuildNavigation from '../../../../hooks/useBuildNavigation';
import { selectFilteredGirlCoordinates, selectGirlsIdsFromActiveChats } from 'redux/selectors/selectors';
import { CENTER_COORDINATES, DEFAULT_ZOOM, MAP_GIRL_FILTERS, MAP_MODES, resetClientData, resetFindedGirlId, resetLocation, resetMapState, updateActiveGirlIds, updateCenter, updateClientCoordinates, updateClientRadius, updateFilter, updateFindedGirlId, updateGirlCoordinateWithContact, updateGirlCoordinateWithProfile, updateGirlsCoordinatesData, updateLocation, updateMode, updateZoom } from 'redux/ducks/map';
 
import './GirlsMapModal.scss';
import Spinner from 'components/UI/Spinner/Spinner';
import GirlsMapModalFilters from './components/GirlsMapModalFilters';
import fetchGirlContactsForBookingCreation from 'utils/fetchGirlContactsForBookingCreation';
import QuickBookingGirlsListItemByDiva from 'containers/Chat/components/QuickBookingGirlsListItem/QuickBookingGirlsListItemByDiva';
import SearchInput from 'components/SearchInput/SearchInput';
import Marker from '../../../../components/UI/Marker/Marker';
import Select from 'components/UI/Select/Select';
import GirlsMapCustomMarker from './components/GirlsMapCustomMarker';
import ContactInfo from '../ContactCard/components/ContactInfo/ContactInfo';

const getClientRadiusBounds = ({ longitude, latitude, radius }) => {
  if (latitude === null || longitude === null || !radius.value) {
    return {};
  }

  const radiusLatitudeMin = latitude - radius.value / 69.172;
  const radiusLatitudeMax = latitude + radius.value / 69.172;

  const milesPerLongitudeDegree = Math.cos(latitude * Math.PI / 180) * 69.172;

  const radiusLongitudeMin = longitude - radius.value / milesPerLongitudeDegree;
  const radiusLongitudeMax = longitude + radius.value / milesPerLongitudeDegree;

  return { radiusLongitudeMin, radiusLatitudeMin, radiusLongitudeMax, radiusLatitudeMax };
}

const convertGirlObjectForSendingModal = ({ girl, profile }) => ({
  ...profile,
  photo: girl?.photo,
  isProfile: true,
})

const createPoint = (marker, category) => ({
  type: 'Feature',
  properties: {
    cluster: false,
    category,
    ...marker
  },
  geometry: {
    type: 'Point',
    coordinates: [
      parseFloat(marker.longitude),
      parseFloat(marker.latitude)
    ],
  },
})

const createPoints = (data, category) => {
  if (category === 'girls') {
    return Object.values(data).map((item) => createPoint(item.girl, 'girls'));
  } else {
    return createPoint(data, 'clients');
  }
}

const getClustersWithDistansionInSimilarCoordinates = (clusters) => {
  for (let i = 0; i < clusters.length; i++) {
    const firstMarker = clusters[i].geometry.coordinates;

    for (let j = i + 1; j < clusters.length; j++) {
      const secondMarker = clusters[j].geometry.coordinates;

      if (firstMarker.latitude === secondMarker.latitude && firstMarker.longitude === secondMarker.longitude) {
        secondMarker.latitude = parseFloat(secondMarker.latitude) + ((Math.random() * 2 - 1) / 10000);
        secondMarker.longitude = parseFloat(secondMarker.longitude) + ((Math.random() * 2 - 1) / 10000);
      }
    }
  }

  return clusters;
}

// const createCientInitialObject = (clientLocation) => {
//   const { latitude, longitude } = clientLocation || {};

//   if (clientLocation) {
//     return { latitude, longitude, radius: { value: 0 } };
//   } else {
//     return CLIENT_INITIAL_OBJECT;
//   }
// }


const GirlsMapModal = (props) => {
  const { clientLocation } = props;

  const {
    zoom,
    center,
    mode,
    modeProps,
    girlsData,
    filter,
    clientData,
    location
  } = useSelector((state) => state.map);

  const callerIdsOfActiveGirlChats = useSelector((state) => state.girlChats.tabs);
  const profileIdsOfActiveGirlChats = useSelector(selectGirlsIdsFromActiveChats);
  const filteredGirlCoordinates = useSelector(selectFilteredGirlCoordinates);

  const [bounds, setBounds] = useState([]);
  const [boundsForApi, setBoundsForApi] = useState([]);
  const prevBoundsForApi = usePrevious(boundsForApi || []);
  const prevMode = usePrevious(mode);

  const [isPending, setIsPending] = useState(false);
  const [isClearCache, setIsClearCache] = useToggle(true);

  const [subwayLines, setSubwayLines] = useState([]);
  const [isShowLines, setIsShowLines] = useState(!!LS.getItem('showMapSubway'));

  const [selectionMode, toggleSelectionMode] = useToggle(false);
  const [selectedGirlsIds, setSelectedGirlsIds] = useState([]);
  const [radiusGirlsIds, setRadiusGirlsIds] = useState([]);

  const [isStreetViewMapLoaded, setIsStreetViewMapLoaded] = useState(false);
  const [streetViewStatus, setStreetViewStatus] = useState(null);

  const {
    initializeNavigation,
    buildNavigation,
    resetNavigation,
  } = useBuildNavigation();

  const dispatch = useDispatch();

  const mapRef = useRef();
  const locationRef = useRef();
  const panoramaContainerRef = useRef();
  const panoramaDivRef = useRef();
  const panoramaRef = useRef();
  const shouldStateBeResetted = useRef(true);

  const saveMapState = () => shouldStateBeResetted.current = false;

  const isSessionEscortsMode = mode === MAP_MODES.CHAT_ESCORTS;
  const isAllEscortsMode = mode === MAP_MODES.ALL_ESCORTS;
  const isNavigationMode = mode === MAP_MODES.NAVIGATION;

  const isAvailableFilter = filter === MAP_GIRL_FILTERS.AVAILABLE_ESCORTS
  const isFindedGirlFilter = filter === MAP_GIRL_FILTERS.FINDED_ESCORT;
  
  const sendEscortsButtonText = selectionMode ? `Send ${selectedGirlsIds.length} Escorts` : 'Send All Escorts';
  
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries: MAP_LIBRARIES,
  });
  
  
  const onSearchBoxLoad = useCallback(ref => locationRef.current = ref, []);
  const mapGirls = Object.values(girlsData).map(({ girl }) => girl).filter(Boolean);
  const selectedGirls = _.pick(girlsData, selectionMode ? selectedGirlsIds : radiusGirlsIds);
  const navigationGirl = girlsData[modeProps.profileId];

  const onPlacesChanged = () => {
    const place = locationRef.current.getPlaces()[0];
    const bounds = new window.google.maps.LatLngBounds();

    if (place.geometry.viewport) {
      bounds.union(place.geometry.viewport);
    } else {
      bounds.extend(place.geometry.location);
    }

    mapRef.current.fitBounds(bounds)

    dispatch(updateClientCoordinates(place));
    dispatch(updateLocation(place.formatted_address));
  };

  useEffect(() => {
    const map = mapRef.current;
    let listenerHandle;

    if (map && isAllEscortsMode) {
      listenerHandle = google.maps.event.addListener(map, 'idle', onMove);
    }
    return () => {
      if (map && isAllEscortsMode) {
        google.maps.event.removeListener(listenerHandle);
      }
    };
  }, [mapRef, isAllEscortsMode]);

  useDidMount(() => {
    dispatch(updateActiveGirlIds(profileIdsOfActiveGirlChats));

    getGirlsCoordinatesByIds();

    return () => shouldStateBeResetted.current && dispatch(resetMapState());
  });

  useEffect(() => {
    if (boundsForApi.toString() === prevBoundsForApi.toString()) return;

    getGirlsCoordinatesByBounds();
  }, [boundsForApi]);

  useEffect(() => {
    const isClientDataEmpty = !clientData.latitude && !clientData.longitude;

    if (isAllEscortsMode && prevMode === MAP_MODES.CHAT_ESCORTS && isClientDataEmpty) {
      setDefaultMapParams();
    }
  }, [mode, clientData]);

  useDeepEffect(() => {
    flyToCurrentGirlMarkers(mapRef.current);
  }, [girlsData, mode]);

  useDidUpdate(() => {
    if (!location) {
      flyToCurrentGirlMarkers(mapRef.current);
      dispatch(resetClientData());
    }
  }, [location])

  useDidUpdate(() => {
    if (!clientData.latitude || !clientData.longitude) {
      return dispatch(resetLocation());
    };

    getGirlsCoordinatesByBounds();
    flyToClientMarker();
  }, [clientData])

  useEffect(() => {
    if (!clientLocation) return;

    dispatch(resetMapState());

    const { latitude, longitude } = clientLocation;

    API.getAddressByCoordinates(Number(latitude), Number(longitude))
      .then(({ data }) => {
        if (!data.results?.length) return;
          dispatch(updateLocation(data.results[0].formatted_address));
        })
  }, [clientLocation])

  useDidUpdate(() => {
    if (isSessionEscortsMode) {
      dispatch(updateFilter(MAP_GIRL_FILTERS.CHAT_ESCORTS));
    }

    if (isAllEscortsMode && prevMode !== MAP_MODES.NAVIGATION) {
      dispatch(updateFilter());
    }

    if (isNavigationMode) {
      const { callerId, profileId, coordinates } = modeProps;

      dispatch(updateGirlCoordinateWithContact(profileId, callerId))
      dispatch(updateGirlCoordinateWithProfile(profileId))
      dispatch(resetFindedGirlId());
      buildNavigationPathToGirl(coordinates);
    } else {
      resetNavigation();
    }
  }, [mode, modeProps])

  useEffect(() => {
    if (mode === 'navigation') return;

    resetNavigation();
  }, [mode])

  useEffect(() => {
    if (!selectionMode && !clientData.radius) {
      setSelectedGirlsIds([]);
    }
  }, [selectionMode, clientData.radius])

  useEffect(() => {
    if (clientData.latitude && clientData.longitude && isSessionEscortsMode) {
      dispatch(updateMode(MAP_MODES.ALL_ESCORTS));
    }
  }, [clientData.latitude, clientData.longitude, mode])

  useEffect(() => {
    setStreetViewStatus(null);

    if (isStreetViewMapLoaded && panoramaDivRef.current) {
      panoramaRef.current = new google.maps.StreetViewPanorama(panoramaDivRef.current, {
        position: { lat: Number(navigationGirl.girl.latitude), lng: Number(navigationGirl.girl.longitude) },
      });

      panoramaRef.current.addListener('status_changed', () => {
        const status = panoramaRef.current.getStatus();

        status && setStreetViewStatus(status);
      });
      
      setTimeout(() => {
        const status = panoramaRef.current.getStatus();

        status && setStreetViewStatus(status);
      }, 650)
    }
  }, [isStreetViewMapLoaded, navigationGirl])

  const setDefaultMapParams = () => {
    dispatch(updateZoom(DEFAULT_ZOOM));
    dispatch(updateCenter(CENTER_COORDINATES));
  };

  const smoothZoom = (map, maxZoom, currentZoom) => {
    if (currentZoom < maxZoom) {
      const listener = google.maps.event.addListener(map, 'zoom_changed', () => {
        google.maps.event.removeListener(listener);
        smoothZoom(map, maxZoom, currentZoom + 1);
      });
      setTimeout(() => {
        dispatch(updateZoom(DEFAULT_ZOOM));
      }, 100);
    }
  };

  const flyTo = (coordinates, newZoom = zoom, isMarker) => {
    const map = mapRef.current;

    if (isMarker) {
      smoothZoom(map, newZoom, map.getZoom())
    } else {
      dispatch(updateZoom(newZoom));
    }

    map.panTo(coordinates);
  };

  const flyToCurrentGirlMarkers = (map) => {
    const sessionGirls = mapGirls.filter(({ caller_id: id }) => callerIdsOfActiveGirlChats.includes(id));

    if (!!map && isSessionEscortsMode && !!sessionGirls.length && !clientData.radius.value) {
      if (sessionGirls.length === 1) {
        flyTo(
          {
            lat: parseFloat(sessionGirls[0].latitude),
            lng: parseFloat(sessionGirls[0].longitude)
          }
        );
      } else {
        const bounds = new google.maps.LatLngBounds();

        sessionGirls.forEach((girl) => {
          bounds.extend({
            lat: parseFloat(girl.latitude),
            lng: parseFloat(girl.longitude),
          })
        });

        map.fitBounds(bounds, 400);
      }
    }
  };

  const flyToClientMarker = () => {
    flyTo({ lat: clientData.latitude, lng: clientData.longitude }, 15 - (clientData.radius?.value || 0) / 2);
  }

  const updateMapState = (map = mapRef.current) => {
    const bounds = map.getBounds();

    setBoundsForApi([
      bounds.getSouthWest().lng(),
      bounds.getSouthWest().lat(),
      bounds.getNorthEast().lng(),
      bounds.getNorthEast().lat(),
    ]);
    // setZoom(map.getZoom());
  };

  const getGirlsCoordinatesByIds = () => {
    setIsPending(true);

    API.getGirlsCoordinatesByIds(callerIdsOfActiveGirlChats)
      .then((data) => dispatch(updateGirlsCoordinatesData(data)))
      .catch(console.error)
      .finally(() => setIsPending(false));
  };

  const getGirlsCoordinatesByBounds = (clearCache = false) => {
    const [longitudeMin, latitudeMin, longitudeMax, latitudeMax] = boundsForApi;

    const config = {
      latitudeMin,
      latitudeMax,
      longitudeMin,
      longitudeMax,
      cacheClear: clearCache || isClearCache,
      ...(clientData.radius.value && {
        radius: clientData.radius.value,
        centerLatitude: clientData.latitude,
        centerLongitude: clientData.longitude,
        ...getClientRadiusBounds(clientData)
      })
    };

    setIsPending(true);

    if (!latitudeMin || !longitudeMin || !latitudeMax || !longitudeMax) return;

    API.getGirlsCoordinatesByBounds(config)
      .then(({ data }) => {
        dispatch(updateGirlsCoordinatesData(data));
        setRadiusGirlsIds(data.radiusProfiles.map(profile => Number(profile.id)));
      })
      .catch(console.error)
      .finally(() => {
        setIsPending(false);
        setIsClearCache(false);
      });
  };


  const drawSubwayLines = (map, maps) => {
    API.getSubwayLines()
      .then(({ data }) => {
        const lines = JSON.parse(data).map((line, index) => {
          const lineCoordinates = line.coordinates;

          const polyline = new maps.Polyline({
            path: lineCoordinates,
            strokeColor: line.color,
            strokeWeight: 2,
            map: map
          });

          if (!isShowLines) polyline.setMap(null);

          return {
            id: index,
            polyline: polyline,
            visible: isShowLines,
          };
        });

        setSubwayLines(lines);
      })
      .catch(console.error);
  };

  const handleClusterClick = (id, latitude, longitude) => {
    const expansionZoom = Math.min(
      supercluster.getClusterExpansionZoom(id),
      25
    );
    dispatch(updateZoom(expansionZoom));
    map.panTo({ lat: latitude, lng: longitude });
  }

  const toggleLinesVisibility = () => {
    setIsShowLines(prevState => {
      LS.setItem('showMapSubway', !prevState);

      return !prevState;
    });

    setSubwayLines((prevLines) => {
      return prevLines.map((line) => {
        const isVisible = !line.visible;

        line.polyline.setMap(isVisible ? mapRef.current : null);

        return { ...line, visible: isVisible };
      });
    });
  };

  const handleFindByName = () => {
    dispatch(openModal(MODAL_TYPES.searchList, {
      fetchData: (props) => fetchGirlContactsForBookingCreation({ dispatch, ...props }),
      itemComponent: QuickBookingGirlsListItemByDiva,
      onChoose: (props) => {
        setIsPending(true);

        API.getGirlOnMap(props.id)
          .then(({ data }) => {
            flyTo({ lat: parseFloat(data[0].latitude), lng: parseFloat(data[0].longitude) }, 14);
            dispatch(updateFilter(MAP_GIRL_FILTERS.FINDED_ESCORT));
            dispatch(updateFindedGirlId(data[0].profile_id));
            // setFindedGirlId(data[0].profile_id);
          })
          .catch(console.error)
          .finally(() => setIsPending(false));
        dispatch(closeModal(MODAL_TYPES.searchList));
      }
    }))
  }

  const handleChangeRadius = (option) => {
    dispatch(updateClientRadius(option));
  }

  const handleSelectGirl = ({ profileId }) => {
    if (!selectedGirlsIds.includes(profileId)) {
      setSelectedGirlsIds((prevState) => [...prevState, profileId]);
      dispatch(updateGirlCoordinateWithProfile(profileId));
    } else {
      setSelectedGirlsIds((prevState) => prevState.filter(id => id !== profileId))
    }
  }

  const openSendGirlsModal = (selectedGirls) => {
    const formatedGirlsForModal = Object.values(selectedGirls).map(convertGirlObjectForSendingModal)
    // const formatedGirlsForModal = _.mapValues(selectedGirls, convertGirlObjectForSendingModal);

    dispatch(openModal(MODAL_TYPES.sendGirlsToClientModal, { selectedGirls: formatedGirlsForModal }));
  }

  const handleClientMarkerClick = () => {
    const nextOption = RADIUS_OPTIONS.find(({ value }) => value === clientData.radius.value + 0.5) || RADIUS_OPTIONS[0];

    handleChangeRadius(nextOption);
  }

  const getHandlerGirlMarkerClick = () => {
    if (selectionMode) {
      return handleSelectGirl;
    }

    if (isNavigationMode) {
      return (modeProps) => dispatch(updateMode(MAP_MODES.NAVIGATION, modeProps));
    }
  }

  const buildNavigationPathToGirl = (coordinates) => {
    resetNavigation();

    const girlPosition = {
      latitude: Number(coordinates.latitude),
      longitude: Number(coordinates.longitude),
    }

    return buildNavigation(clientData, girlPosition)
  }

  const handleCreateOption = (inputValue) => {
    const newLabel = inputValue.match(/\d+/g) + ' miles';
    const newValue = { value: inputValue, label: newLabel };

    handleChangeRadius(newValue);
  }

  const onStreetViewMapLoad = () => {
    setIsStreetViewMapLoaded(true);
  }

  const onStreetViewMapUnmount = () => {
    setIsStreetViewMapLoaded(false);
  }

  const defaultHeader = useMemo(() => (
    <div className="girls-map__header">
      <div className="girls-map__description">
        Showing escorts with which you are having open conversation in chats.
      </div>

      <button
        className="girls-map__toggle-btn"
        onClick={() => dispatch(updateMode(MAP_MODES.ALL_ESCORTS))}
      >
        Show All Escorts
        <ICONS.puzzle className="girls-map__toggle-btn-icon" />
      </button>
    </div>
  ), []);

  const allEscortsModeHeader = useMemo(() => (
    <div className="girls-map__header">
      <GirlsMapModalFilters
        flyTo={flyTo}
        getGirlsCoordinatesByBounds={getGirlsCoordinatesByBounds}
      />

      <div className="girls-map__buttons-wrap">
        <button
          className={classModifier("girls-map__toggle-btn", [isFindedGirlFilter && 'active'])}
          onClick={() => isFindedGirlFilter ? dispatch(updateFilter()) : handleFindByName()}
        >
          Find Escort By Name
        </button>

        <button
          className="girls-map__toggle-btn"
          onClick={() => {
            dispatch(resetClientData());
            dispatch(updateMode(MAP_MODES.CHAT_ESCORTS));
          }}
          title='Show escorts from active chats'
        >
          Show Chat Escorts
          <ICONS.puzzle className="girls-map__toggle-btn-icon" />
        </button>

        <button
          className={classModifier('girls-map__toggle-btn', [
            isAvailableFilter && 'active',
          ])}
          onClick={() => dispatch(updateFilter(MAP_GIRL_FILTERS.AVAILABLE_ESCORTS))}
        >
          Available Now
          <ICONS.check className="girls-map__toggle-btn-icon" />
        </button>
      </div>
    </div>
  ), [filter])

  const navigationModeHeader = useMemo(() => (
    <div className="girls-map__header">
      <div className="girls-map__buttons-wrap">
        <button
          className='girls-map__back-btn'
          onClick={() => dispatch(updateMode(MAP_MODES.ALL_ESCORTS))}
        >
          <ICONS.arrow />
          Back to full map
        </button>

        <button
          className="girls-map__toggle-btn"
          onClick={() => {
            return dispatch(openModal(MODAL_TYPES.sendNavigationPathModal, {
              origin: clientData,
              destination: {
                latitude: Number(navigationGirl.girl.latitude),
                longitude: Number(navigationGirl.girl.longitude),
              },
              // mapService,
            }
            ))
          }}
        >
          Send Directions
          <ICONS.mapDash className="girls-map__toggle-btn-icon" />
        </button>

        <button
          className="girls-map__toggle-btn"
          onClick={() => openSendGirlsModal({ [modeProps.profileId]: navigationGirl })}
        >
          Send Escort
          <ICONS.signOut className="girls-map__toggle-btn-icon" />
        </button>
      </div>
    </div>
  ), [clientData, navigationGirl]);

  const header = {
    [MAP_MODES.CHAT_ESCORTS]: defaultHeader,
    [MAP_MODES.ALL_ESCORTS]: allEscortsModeHeader,
    [MAP_MODES.NAVIGATION]: navigationModeHeader,
  }

  const onMove = useCallback(updateMapState, []);

  const girlsPoints = createPoints(filteredGirlCoordinates, 'girls');
  const clientPoint = createPoint(clientData, 'clients');

  const { clusters, supercluster } = useSupercluster({
    points: [...girlsPoints, clientPoint],
    bounds,
    zoom,
    options: { radius: 75, maxZoom: 20 }
  });

  return (
    <div className={classModifier("girls-map", selectionMode && 'selected-mode')}>
      <div className="girls-map__header-wrapper">
        {header[mode]}

        <button
          className="girls-map__esc-btn"
          onClick={() => dispatch(closeModal())}
        >
          <ICONS.close className="girls-map__esc-btn-icon" /> (Esc)
        </button>
      </div>

      {isLoaded && (
        <div className="girls-map__content">
          {isNavigationMode && (
            <>
              <div className="girls-map__content-preview" ref={panoramaContainerRef}>
                {navigationGirl ? (
                  <>
                    <GoogleMap
                      onLoad={onStreetViewMapLoad}
                      onUnmount={onStreetViewMapUnmount}
                      defaultCenter={CENTER_COORDINATES}
                      defaultZoom={zoom}
                    >
                      {isStreetViewMapLoaded && (
                        <div
                          ref={panoramaDivRef}
                          className="girls-map__street-view"
                        />
                      )}
                    </GoogleMap>

                    {streetViewStatus === 'ZERO_RESULTS' && (
                      <div className={classModifier('girls-map__street-view', 'fallback')}>
                        Street view is unavailable for this location
                      </div>
                    )}
                  </>
                ) : (
                  <Spinner spinnerSize={44} />
                )}
              </div>

              <div className="girls-map__content-contact">
                {navigationGirl?.contact ? (
                  <ContactInfo
                    contact={navigationGirl.contact.id}
                    activeRecipient={navigationGirl.contact}
                    closeModal={() => dispatch(closeModal())}
                    saveMapState={saveMapState}
                  />
                ) : <Spinner spinnerSize={44} />}
              </div>
            </>
          )}

          <div className="girls-map__content-map">
            {isPending && (
              <div className="girls-map__spinner-wrap">
                <Spinner spinnerSize={44} />
              </div>
            )}

            <button
              className={classModifier('girls-map__toggle-btn', [
                isShowLines && 'active',
                'subway',
              ])}
              onClick={toggleLinesVisibility}
            >
              Subway
              <ICONS.check className="girls-map__toggle-btn-icon" />
            </button>

            <GoogleMapReact
              className="girls-map__google-map"
              zoom={zoom}
              center={center}
              options={MAP_DEFAULT_OPTIONS}
              yesIWantToUseGoogleMapApiInternals
              shouldUnregisterMapOnUnmount={false}
              bootstrapURLKeys={{
                key: GOOGLE_MAPS_API_KEY,
                language: 'en',
              }}
              onGoogleApiLoaded={({ map, maps }) => {
                mapRef.current = map;

                if (clientLocation) {
                  const { latitude, longitude } = clientLocation;
  
                  dispatch(updateClientCoordinates({ latitude, longitude }));

                  if (clientData.latitude && clientData.longitude) {
                    flyToClientMarker();
                  } else {
                    flyTo({ lat: Number(latitude), lng: Number(longitude) });
                  }
                } else {
                  flyToCurrentGirlMarkers(map);
                }
                drawSubwayLines(map, maps);
                initializeNavigation(map);
                updateMapState(map);

                if (isNavigationMode) {
                  buildNavigationPathToGirl(modeProps.coordinates);
                }
              }}
              onChange={({ zoom, bounds, center }) => {
                dispatch(updateZoom(zoom));
                dispatch(updateCenter(center));
                setBounds([
                  bounds.nw.lng,
                  bounds.se.lat,
                  bounds.se.lng,
                  bounds.nw.lat,
                ]);
              }}
            >
              {getClustersWithDistansionInSimilarCoordinates(clusters).map(cluster => {
                const [longitude, latitude] = cluster.geometry.coordinates;
                const {
                  cluster: isCluster,
                  point_count: pointCount,
                  category,
                  radius,
                  caller_id,
                  profile_id,
                } = cluster.properties;

                const initialProps = {
                  lat: latitude,
                  lng: longitude,
                  key: cluster.properties.id,
                  onDoubleClick: e => e.stopPropagation(),
                }

                if (isCluster) {
                  const clusteredMarkers = supercluster.getLeaves(cluster.id);
                  const isClientMarkerClustered = clusteredMarkers.some(({ properties }) => properties.category === 'clients');
                  const isClusterSelected = clusteredMarkers.every(({ properties }) => selectedGirlsIds.includes(properties.profile_id));

                  const { radius } = clientData;

                  return (
                    <Marker
                      className="cluster-marker"
                      onClick={() => handleClusterClick(cluster.id, latitude, longitude)}
                      selected={selectionMode && isClusterSelected}
                      {...initialProps}
                      {...(isClientMarkerClustered && { radius, zoom })}
                    >
                      {pointCount}
                    </Marker>
                  )
                }

                if (category === 'clients' && radius) {
                  return (
                    <Marker
                      className="client-marker"
                      radius={radius.value}
                      zoom={zoom}
                      onClick={handleClientMarkerClick}
                      {...initialProps}
                    >
                      {radius.value || '?'}
                    </Marker>
                  )
                }

                if (category === 'girls') {
                  const handleClick = getHandlerGirlMarkerClick();
                  const navigationModeProps = {
                    coordinates: cluster.properties,
                    callerId: caller_id,
                    profileId: profile_id,
                  }
                  const isSelected = !isNavigationMode && selectionMode && selectedGirlsIds.includes(profile_id);

                  return (
                    <GirlsMapCustomMarker
                      girlInformation={cluster.properties}
                      selected={isSelected}
                      clientPosition={clientData}
                      navigationModeProps={navigationModeProps}
                      saveMapState={saveMapState}
                      {...(handleClick && { onClick: () => handleClick(navigationModeProps) })}
                      {...initialProps}
                    />
                  )


                }
              })}
            </GoogleMapReact>
          </div>
        </div>
      )}

      <div className="girls-map__footer">
        {!isNavigationMode && (
          <>
            <div className="girls-map__footer-side">
              {isLoaded && (
                <div className="girls-map__filter-wrap">
                  <label
                    className="girls-map__filter-label"
                    htmlFor="location"
                  >
                    Enter client location
                  </label>

                  <StandaloneSearchBox
                    onLoad={onSearchBoxLoad}
                    onPlacesChanged={onPlacesChanged}
                  >
                    <SearchInput
                      name="location"
                      inputWrapClassName="girls-map__client-location-input-wrap"
                      inputClassName="girls-map__client-location-input"
                      showSearchBtn
                      showClearBtn
                      query={location}
                      setQuery={(value) => dispatch(updateLocation(value))}
                      iconSize="small"
                    />
                  </StandaloneSearchBox>
                </div>
              )}

              <div className="girls-map__filter-wrap">
                <label
                  className="girls-map__filter-label"
                  htmlFor="radius"
                >
                  Radius
                </label>

                <Select
                  className={classNames("react-select", "girls-map__radius-select")}
                  value={clientData.radius.value ? clientData.radius : null}
                  type={SELECT_THEMES.LIGHT}
                  options={RADIUS_OPTIONS}
                  onChange={handleChangeRadius}
                  isDisabled={!clientData.latitude || !clientData.longitude || !location}
                  creatable
                  isValidNewOption={(input) => Number(input) < 10}
                  onCreateOption={handleCreateOption}
                />
              </div>
            </div>

            <div className="girls-map__footer-side">
              {selectionMode && "Click markers to select/deselect"}

              <button
                className={classModifier("girls-map__toggle-btn", selectionMode && 'selected')}
                onClick={toggleSelectionMode}
              >
                {selectionMode ? 'Exit Selection' : 'Select Escorts'}
                <ICONS.check className="girls-map__toggle-btn-icon" />
              </button>

              {(selectionMode || clientData.radius) && (
                <button
                  className={classModifier("girls-map__toggle-btn", "send")}
                  onClick={() => openSendGirlsModal(selectedGirls)}
                  disabled={_.size(selectedGirls) === 0}
                >
                  {sendEscortsButtonText}
                  <ICONS.signOut className="girls-map__toggle-btn-icon" />
                </button>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default GirlsMapModal;
