import React from 'react'
import { useNavigate } from 'react-router-dom';

const AttachmentEmail = ({ email }) => {
  const navigate = useNavigate();

  const onClick = () => {
    // navigate(`/client/mail/inbox/email/${email?.conversationId}`);
  }

  return (
    <div className="attachment-email" onClick={onClick}>
      <p className="attachment-email__title">
        {email.subject}
      </p>
      <p className="attachment-email__body">
        {email.body}
      </p>
    </div>
  )
}

export default AttachmentEmail;
