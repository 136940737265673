import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';
import classNames from 'classnames';
import Select from 'react-select';
import arrayMutators from 'final-form-arrays';
import { v4 as uuid } from 'uuid';

import API from 'api/api';
import ICONS from 'assets/icons';
import { isEmptyObj, getIsFieldEmpty, classModifier, getContactAvatar } from 'utils';
import {
  updateContact,
  removeContact,
  addNewContact,
  removeAgentData,
  addAgentData,
  updateAgentsGirlIds,
} from 'redux/ducks/contacts';
import {
  CHAT_TYPES,
  CLIENT_CATEGORY_OPTIONS,
  CLIENT_SUBTYPE_OPTIONS,
  CONTACT_TYPES,
} from 'config/constants';
import { closeModal } from 'redux/ducks/activeWindows';
import { useDidUpdate, useDidMount, useToggle, useCancelToken } from 'hooks';
import { selectAgentContactById, selectAgentsList, selectContactById } from 'redux/selectors/selectors';

import './ContactEditForm.scss';
import Spinner from 'components/UI/Spinner/Spinner';
import TagsField from 'components/ContactFormItems/TagsField';
import ViaItems from 'components/ContactFormItems/ViaItems/ViaItems';
import FormTextTextarea from 'components/FormTextTextarea/FormTextarea';
import NotForField from 'components/ContactFormItems/NotForField/NotForField';
import EmailsField from 'components/ContactFormItems/EmailsField/EmailsField';
import ContactAvatar from 'containers/AdrBookContactFormWrap/components/ContactAvatar';
import PhoneNumbersField from 'components/ContactFormItems/PhoneNumbersField/PhoneNumbersField';
import AdrBookContactFormAutoSave from 'containers/AdrBookContactFormWrap/components/AdrBookContactForm/AdrBookContactFormAutoSave';
import ContactAddressTab from 'containers/AdrBookContactFormWrap/components/AdrBookContactFormTabs/components/ContactAddressTab/ContactAddressTab';
import FormTextInput from 'components/FormTextInput/FormTextInput';
import NicknameTelegramField from 'components/ContactFormItems/NickNameTelegramField/NicknameTelegramField';
import ContactTags from 'components/ContactTags/ContactTags';
import ViaItem from 'components/ContactFormItems/ViaItems/components/ViaItem/ViaItem';

const clientTypeOptions = [
  { label: 'Client', value: CONTACT_TYPES.CLIENT },
  { label: 'Girl', value: CONTACT_TYPES.GIRL },
  { label: 'Agent', value: CONTACT_TYPES.AGENT },
  { label: 'Services', value: CONTACT_TYPES.SERVICES },
  { label: 'Reception', value: CONTACT_TYPES.RECEPTION },
  { label: 'Network Provider', value: CONTACT_TYPES.NETWORK_PROVIDER },
]

const ContactEditForm = props => {
  const [editedContact, setEditedContact] = useState(props.contact);
  const [avaCropper, toggleAvaCropper] = useToggle(false);
  const [photo, setPhoto] = useState({
    url: null,
    file: null,
  });
  const [initialValues, setInitialValues] = useState({});
  const [phoneLabels, setPhoneLabels] = useState([]);
  // const [newContactTags, setNewContactTags] = useState([]);
  // const [editedContactTags, setEditedContactTags] = useState([]);
  // const [initialTags, setInitialTags] = useState([]);
  const [isEditedTags, setIsEditedTags] = useState(false);
  const [subtypes, setSubtypes] = useState(CLIENT_SUBTYPE_OPTIONS);
  const [isLoadingImage, setIsLoadingImage] = useState(false);


  const [isUndo, setIsUndo] = useState(false);
  const [serverError, setServerError] = useState('');
  const [pending, setPending] = useState(false);
  const [historyStateList, setHistoryStateList] = useState([editedContact]);

  const { newCancelToken, cancelPrevRequest } = useCancelToken();
  
  const [lastStateListItem] = historyStateList.slice(-1);

  const isContactWithAgent = (values) => {
    const isGirlType = +values.type === +CONTACT_TYPES.GIRL;
    const isReceptionType = +values.type === +CONTACT_TYPES.RECEPTION;

    return isGirlType || isReceptionType;
  }

  const agentsOptions = useMemo(() => [
    { label: '(no agent)', value: null, color: '#808080' },
    ...props.agents.map(agent => ({ label: agent.fn, value: agent.id }))
  ], [props.agents]);

  const selectAgentStyles = useMemo(() => ({
    singleValue: (provided, { data }) => ({
      ...provided,
      color: `${data.color} !important`,
    })
  }), []);

  useEffect(() => {
    setEditedContact(props.contact);
  }, [props.contact]);

  useDidMount(() => {
    let addingFields = {};

    Promise.all([
      API.getPhoneLabels(),
      API.getLinksLabels()
    ])
    .then(results => {
      const loadedTelLabels = results[0].data;
      const loadedLinksLabels = results[1].data;

      let telsOptions = [];
      let labelsOptions = [];

      let defaultTelLabel = '';

      if (loadedTelLabels.length) {
        loadedTelLabels.map(label => {
          if (label) telsOptions.push({ value: label.title, label: label.title, id: label.id })
        });

        defaultTelLabel = loadedTelLabels[0];
      }
      else {
        telsOptions.push({ value: 'default', label: 'default' });
        defaultTelLabel = 'default';
      }

      if (loadedLinksLabels.length) {
        loadedLinksLabels.map(label => {
          if (label) labelsOptions.push({ value: label, label})
        });
      }
      else {
        labelsOptions.push({ value: 'default', label: 'default' });
      }

      setPhoneLabels(telsOptions);

      editedContact?.photo
        ? setPhoto({ url: editedContact.photo.src, file: null })
        : setPhoto({ url: getContactAvatar({ type: +editedContact.type }), file: null });

      const initialize = (addingFields) => {
        const idFromFn = editedContact.fn.includes(' ') 
          ? editedContact.fn?.match(/^\d+/ig)?.[0] 
          : null;
        const fnForNotClient = editedContact.fn?.replace(idFromFn || '', '');

        const nameValue = !editedContact.name && editedContact.fn
          ? fnForNotClient
          : editedContact.name;

        setInitialValues({
          ...editedContact,
          ...addingFields,
          custom_id: idFromFn || editedContact.custom_id,
          name: nameValue,
          addresses: editedContact.addresses ? editedContact.addresses : [],
          ...(+editedContact.type === +CONTACT_TYPES.CLIENT ? { custom_id: idFromFn || editedContact.custom_id } : {}),
        });
      };

      if (props.isAddPhoneNumberOnMount) {
        addingFields.tels = [
          ...editedContact.tels,
          {
            default: +!editedContact.tels.length,
            labelName: 'default',
            uuid: uuid(),
          },
        ];
      }

      if (props.isAddEmailFieldOnMount) addingFields.emails = [ ...editedContact.emails, { default: 1 }];

      if (!props.contact) {
        addingFields.type = props.isGirls
          ? CHAT_TYPES.GIRL
          : CHAT_TYPES.CLIENT;

        return initialize(addingFields);
      }

      if (editedContact.type === 2) {
        addingFields.agent = props.defaultAgent;

        return initialize(addingFields);
      }

      initialize(addingFields);
    })
    .catch(err => console.error(err));
  });

  useDidUpdate(() => {
    if(!avaCropper && photo.file) {
      saveContact({
        ...editedContact,
        image: photo.file
      })
    }
  }, [avaCropper]);

  const saveContact = (values, undo = false) => {
    setPending(true);
    serverError && setServerError('');

    if (!props.contact) {
      props.addNewContact({
        ...values,
        image: photo.file,
      })
        .then(() => setPending(false))
        .catch(err => {
          setPending(false);
          setServerError(JSON.parse(err.response.data.message));
        })
      return;
    }

    cancelPrevRequest();

    const fn = [
      values.custom_id || editedContact.custom_id,
      values.category && `Client ${values.category}`,
      values.name,
      values.description
    ].filter(Boolean).join(' ');

    const params = {
      ...values,
      image: values.file || values.photo?.src,
      addresses: values.addresses || [],
      fn: +values.type === +CONTACT_TYPES.CLIENT ? fn : values.fn,
      // callerTags: undo
      //   ? values.callerTags || []
      //   : isNewContactType
      //     ? []
      //     : editedContactTags,
    }

    const isDeleteCustomId = !!+props.contact.custom_id && !+values.custom_id;

    props.updateContact(params, editedContact.type, newCancelToken(), isDeleteCustomId)
      .then(res => {
        setPending(false);
        
        isUndo && setIsUndo(false);

        setPhoto({
          url: res.data.photo ? res.data.photo.src : null,
          file: null
        });
        setInitialValues(res.data);

        if (undo) {
          setHistoryStateList(prev => prev.slice(0, -1));
          initialize(res.data);
        } else {
          setHistoryStateList([...historyStateList, res.data]);
        }
        // setEditedContact(res.data);
      })
      .catch(err => {
        setPending(false);

        const errMsg = err.response?.data?.message;
        err.__CANCEL__ && setPending(true);
        err.response && setServerError(typeof errMsg === 'object' ? JSON.parse(errMsg) : errMsg);
      })
      .finally(() => setIsLoadingImage(false));
  };

  const onChangeContactCategory = (option, form, values) => {
    form.change('category', option.value);
    // setEditedContact({ ...editedContact, category: option.value, isEdited: true });
    onChangeFullName(form.change, values, { category: option.value });
  };

  const renderContactTypeField = (values, form) => {
    return (
      <div
        className={classModifier('edit-contact-form__field', 'type')}
      >
        Type:
        <Select
          options={clientTypeOptions}
          isSearchable={false}
          className="react-select"
          classNamePrefix="react-select"
          defaultValue={clientTypeOptions[0]}
          value={clientTypeOptions.find(el => Number(el.value) === values.type)}
          onChange={async (option) => {
            await form.change('type', option.value);
        
            if (option.value !== lastStateListItem.type) {
              saveContact(form.getState().values);
            }
          }}
          getOptionValue={option => option.value}
        />
      </div>
    );
  };

  const deleteContactAvatar = (values) => {
    if(window.confirm("Delete avatar?")) {
      setIsLoadingImage(true);

      setPhoto({ url: null, file: null, src: null });
      saveContact({ 
        ...values, 
        file: null,
        photo: { url: null, file: null, src: null }
      });
    }
  }

  const changeContactAvatar = (file, isHardImgLoad) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      if (file.type.startsWith("image")) {
        setPhotoHandler(file, isHardImgLoad);
      }
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const setPhotoHandler = (file, isHardImgLoad) => {
    if(isHardImgLoad) {
      setPhoto({ file, url: file ? URL.createObjectURL(file) : null });
    } else {
      setIsLoadingImage(true);
      setPhoto({ 
        src: typeof file === 'string' ? file : null,
        file, 
        url: typeof file === 'string' ? null : file ? URL.createObjectURL(file) : null,
      })
    }
  }

  const getUpdatedFullName = (formValues, updatedValue) => {
    const values = { ...formValues, ...updatedValue };

    const fn = [
      values.custom_id || editedContact.custom_id,
      values.category && `Client ${values.category}`,
      values.name,
      values.description
    ].filter(Boolean).join(' ');

    return fn;
  }

  const onChangeFullName = (formChange, prevValues, currValue = {}, isClearField) => {
    const fn = getUpdatedFullName(prevValues, currValue);

    formChange('fn', fn);

    if (isClearField) {
      const [fieldName] = Object.keys(currValue);

      formChange(fieldName, '');
      saveContact({ ...prevValues, fn, [fieldName]: '', });
    }
  };

  const setPrevState = () => {
    const [penultimateStateItem] = historyStateList.slice(-2, -1);

    setIsUndo(true);
    saveContact(penultimateStateItem, true);
  };

  const isEditedTelLabel = (values) => values.tels?.some((obj, idx) => obj?.labelName !== lastStateListItem.tels[idx]?.labelName);

  // const isEditedTags = useMemo(() => (
  //   JSON.stringify(
  //     initialTags
  //     .map((tag) => tag.title)
  //     .slice()
  //     .sort()
  //   ) !==
  //   JSON.stringify(
  //     editedContactTags
  //     .map((tag) => tag.title)
  //     .slice()
  //     .sort()
  //   )), [initialTags, editedContactTags]
  // );

  const isNewContactCreation = isEmptyObj(editedContact);

  return (
    <Form
      onSubmit={saveContact}
      validate={validate}
      initialValues={initialValues}
      mutators={arrayMutators}
      render={({ handleSubmit, form, values }) => {
        const errorMsgToString = typeof serverError === 'object' ? JSON.stringify(serverError) : serverError;
        const isDisabledSubtypeSelect = subtypes.length === 1;

        useEffect(() => {
          if(values.category) {
            const updatedSybtypesList = CLIENT_SUBTYPE_OPTIONS.filter(option => option.value === 0);
            setSubtypes(updatedSybtypesList);
            form.change('subtype', 0);
          } else {
            setSubtypes(CLIENT_SUBTYPE_OPTIONS);
          }
      
          if(+values.subtype !== 0) {
            form.change('category', null)
          }
        }, [values.category, values.subtype]);  

        return (
          <form onSubmit={handleSubmit} className="edit-contact-form">
            <AdrBookContactFormAutoSave
              isPhotoFile={photo.file}
              isEditedTags={isEditedTags}
              lastStateListItem={lastStateListItem}
              saveContact={() => saveContact(values)}
            />

            <header className="edit-contact-form__header">
              <Field
                name='image'
                photo={photo}
                values={values}
                isLoadingImage={isLoadingImage}
                // avaCropper={avaCropper}
                component={ContactAvatar}
                cropSubmit={setPhotoHandler}
                editedContact={editedContact}
                // toggleAvaCropper={toggleAvaCropper}e
                classPrefix="edit-contact-form-avatar"
                onChangeContactAvatar={changeContactAvatar}
                onDeleteContactAvatar={() => deleteContactAvatar(values)}
                cropperClassPrefix="edit-contact-form-img-cropper"
                saveContact={(file) => saveContact({ ...values, file })}
              />

              <div className="edit-contact-form__header-group">
                {(+values.type === +CONTACT_TYPES.CLIENT) ? (
                  <Field name="name">
                    {({ input, ...fieldProps }) => (
                      <FormTextTextarea
                        {...fieldProps}
                        autosize
                        getErrors={serverError}
                        placeholder="Name"
                        autoFocus={props.autoFocusInput === "fn"}
                        className="edit-contact-form__textarea-wrapper"
                        inputClassName={classModifier("edit-contact-form__input", "fn")}
                        {...(props.autoFocusInput === "fn" ? { onBlur: () => values?.fn && saveContact(values) } : {})}
                        input={{
                          ...input, onChange: (e) => {
                            input.onChange(e);
                            setEditedContact({ ...editedContact, fn: e.target.value, isEdited: true });
                            onChangeFullName(form.change, values, { name: e.target.value });
                          }
                        }}
                      />
                    )}
                  </Field>
                ) : (
                  <Field
                    name="fn"
                    autosize
                    component={FormTextTextarea}
                    getErrors={serverError}
                    placeholder="Full Name"
                    className="edit-contact-form__textarea-wrapper"
                    inputClassName={classModifier("edit-contact-form__input", "fn")}
                    autoFocus={props.autoFocusInput === "fn"}
                    {...(props.autoFocusInput === "fn" ? { onBlur: () => values?.fn && saveContact(values) } : {})}
                  />
                )}
              </div>
            </header>

            {errorMsgToString && <span className='edit-contact-form__error-msg'>{errorMsgToString}</span>}

            <div className='edit-contact-form__information'>
              <Field name="custom_id">
                {({ input, ...fieldProps }) => (
                  <FormTextInput
                    {...fieldProps}
                    autoComplete="off"
                    serverErrors={serverError}
                    input={{ ...input, onChange: (e) => {
                      input.onChange(e);
                      onChangeFullName(form.change, values, { custom_id: e.target.value });
                    }}}
                    // inputProps={{ disabled: 'disabled' }}
                    className={classModifier('edit-contact-form__input', 'custom_id')}
                    showTooltipWhenOvercrowded
                  />
                )}
              </Field>

              {(+values.type === +CONTACT_TYPES.CLIENT) && (
                <div
                  className={classModifier('edit-contact-form__field', ['category', (values.subtype !== 0) && 'disabled'])}
                >
                  <Select
                    options={CLIENT_CATEGORY_OPTIONS}
                    isSearchable={false}
                    className="react-select"
                    classNamePrefix="react-select"
                    defaultValue={{ value: null, label: '' }}
                    value={CLIENT_CATEGORY_OPTIONS.find(el => el.value === values.category)}
                    onChange={option => onChangeContactCategory(option, form, values)}
                    getOptionValue={option => option.value}
                    onBlur={() => values.category !== lastStateListItem.category && saveContact(values)}
                    isDisabled={values.subtype !== 0}
                  />
                </div>
              )}

              {(+values.type !== +CONTACT_TYPES.CLIENT) && renderContactTypeField(values, form)}

              {(+values.type === +CONTACT_TYPES.GIRL ||
                +values.type === +CONTACT_TYPES.AGENT ||
                +values.type === +CONTACT_TYPES.RECEPTION) && (
                <ViaItem
                  viaFieldName="is_ex"
                  setValues={form.change}
                  valueToChange={values.fn}
                  saveContact={(field, value) =>
                    !isNewContactCreation &&
                    saveContact({ ...values, [field]: value })
                  }
                  viaTitle={<div className="adr-book-contact-form__ex">EX</div>}
                />
              )}
            </div>

            {(isContactWithAgent(values)) && (
              <div className='edit-contact-form__girl-agent'>
                <button
                  type="button"
                  className="edit-contact-form__agent-btn"
                  >
                    <div
                      className={classModifier('edit-contact-form__field', 'agent')}
                    >
                      Agent:
                      <Select
                        options={agentsOptions}
                        className="react-select"
                        classNamePrefix="react-select"
                        defaultValue={agentsOptions[0]}
                        styles={selectAgentStyles}
                        value={agentsOptions.find(el => el.value === values.agentId)}
                        onChange={(option) => {
                          form.change('agentId', option.value);
                            
                          if (option.value !== lastStateListItem.type) {
                            saveContact(form.getState().values);
                          }
                        }}
                        getOptionValue={option => option.value}
                      />
                    </div>
                </button>
              </div>)
            }

            {(+values.type === +CONTACT_TYPES.CLIENT) && (
              <div className='edit-contact-form__types'>
                {renderContactTypeField(values, form)}

                <div
                  className={classModifier('edit-contact-form__field', [
                    'type', 
                    'subtype', 
                    isDisabledSubtypeSelect && 'disabled'
                  ])}
                >
                  Subtype:
                  <Select
                    options={subtypes}
                    isSearchable={false}
                    className="react-select"
                    classNamePrefix="react-select"
                    defaultValue={subtypes[0]}
                    value={subtypes.find(el => el.value === values.subtype)}
                    onChange={option => form.change('subtype', option.value)}
                    getOptionValue={option => option.value}
                    onBlur={() => values.subtype !== lastStateListItem.subtype && saveContact(values)}
                    isDisabled={isDisabledSubtypeSelect}
                  />
                </div>
              </div>
            )}

            <main className="edit-contact-form__main">
              {(+values.type === +CONTACT_TYPES.CLIENT) &&
                <div className={classModifier('edit-contact-form__input-wrap', 'description')}>
                  <span className='edit-contact-form__input-title'>Description:</span>
                  <Field name="description">
                    {({ input, ...fieldProps }) => (
                      <FormTextInput
                        {...fieldProps}
                        autoComplete="off"
                        serverErrors={serverError}
                        input={{ ...input, onChange: (e) => {
                          input.onChange(e);
                          setEditedContact({ ...editedContact, description: e.target.value, isEdited: true });
                          onChangeFullName(form.change, values, { description: e.target.value });
                        }}}
                        className={classModifier('edit-contact-form__input', 'description')}
                        showTooltipWhenOvercrowded
                      />
                    )}
                  </Field>

                  {values.description &&
                    <button
                      type="button"
                      className="edit-contact-form__btn-clear"
                      onClick={() => {
                        onChangeFullName(form.change, values, { description: '' }, true)
                      }}
                    >
                      <ICONS.close className="edit-contact-form__btn-clear-icon"/>
                    </button>
                  }
                </div>
              }

              {/* <TagsField
                values={values}
                contactType={props.contact.type}
                saveContact={saveContact}
                isEditedTags={isEditedTags}
                editedContact={props.contact}
                editedContactTags={editedContactTags}
                setEditedContactTags={setEditedContactTags}
                newContactTags={newContactTags}
                setNewContactTags={setNewContactTags}
                isNewContactCreation={isEmptyObj(editedContact)}
              /> */}
              <ContactTags
                callers={editedContact}
                wrapper={TagsField}
                wrapperProps={{
                  values,
                  contactType: props.contact.type,
                  saveContact,
                  isNewContactCreation,
                  isEditedTags,
                  setIsEditedTags,
                }}
              />

              <div className={classNames('edit-contact-form__field', 'tags-field-container')}>
                <h3 className={classModifier('edit-contact-form__field-title', 'via')}>Contact only via:</h3>
                <div className='edit-contact-form__via-list'>
                  <ViaItems
                    telsValues={values.tels}
                    emailsValues={values.emails}
                  />
                </div>
              </div>

              <PhoneNumbersField
                values={values}
                saveContact={saveContact}
                classPrefix="edit-contact-form"
                phoneLabels={phoneLabels}
                setPhoneLabels={setPhoneLabels}
                serverError={serverError.tels}
                setServerError={setServerError}
                isEditedTelLabel={isEditedTelLabel(values)}
                isNewContactCreation
              />

              <EmailsField
                values={values}
                saveContact={saveContact}
                classPrefix="edit-contact-form"
                phoneLabels={phoneLabels}
                serverError={serverError.emails}
                setServerError={setServerError}
                isNewContactCreation
              />

              <NicknameTelegramField
                values={values}
                saveContact={saveContact}
                classPrefix="edit-contact-form"
                serverError={serverError.emails}
                setServerError={setServerError}
                isNewContactCreation
              />

              <div className={classNames("edit-contact-form__addresses", "tags-field-container")}>
                <ContactAddressTab
                  values={values}
                  saveContact={saveContact}
                  serverError={serverError}
                  editedContact={editedContact}
                  setServerError={setServerError}
                />
              </div>

              {+values.type === CONTACT_TYPES.CLIENT &&
                <NotForField
                  callerId={editedContact.id}
                  classPrefix="edit-contact-form"
                  notForClients={props.notForClients}
                />
              }

              <div className={classNames("edit-contact-form__notes", "tags-field-container")}>
                <h3 className="edit-contact-form__field-title">Notes:</h3>

                <Field
                  name="note"
                  component={FormTextTextarea}
                  className="edit-contact-form__notes-textarea-container"
                />
              </div>

              {pending
                ? <div className="edit-contact-form__spinner-wrapper">
                    <Spinner spinnerSize={30} className="edit-contact-form__icon-action" />
                  </div>
                : historyStateList.length > 1 &&
                    <button
                      className="edit-contact-form__btn-undo"
                      type="button"
                      onClick={setPrevState}
                      disabled={pending}
                    >
                      <span>Saved! Undo?</span>
                      <ICONS.undo color='#00A6AD' className='edit-contact-form__icon-action' />
                    </button>
              }
            </main>
          </form>
        )
      }}
    />
  );
};

const validate = ({ fn, tels, emails, urls, telegram_nicknames }) => {
  const errors = {
    tels: [],
    emails: [],
    urls: [],
    telegram_nicknames: [],
  };

  if (tels) {
    tels.forEach((currentTel, index) => {
      const tel = currentTel.tel?.trim();

      if (!tel) {
        return errors.tels[index] = { error: 'Enter the phone number' };
      }
      const numbers = /^\d+$/;
      const isCorrect = tel[0] === '+'
        ? numbers.test(tel.slice(1))
        : numbers.test(tel);

      if (!isCorrect) {
        return errors.tels[index] = { error: 'Incorrect phone number' };
      }
      const countOfCoincidence = tels.filter(t => t.tel?.trim() === tel).length;

      if (countOfCoincidence > 1) {
        return errors.tels[index] = { error: 'Phone number has already exist' };
      }
    })
  }

  if (emails) {
    emails.forEach((currentEmail, index) => {
      const { email } = currentEmail;

      if (!email) {
        return errors.emails[index] = { error: 'Enter the email' };
      }
      const emailRegExp = /[A-Z0-9._%+-]+@[A-Z0-9-]+.+.[A-Z]/igm;

      if (!emailRegExp.test(email)) {
        return errors.emails[index] = { error: 'Incorrect email' };
      }
      const countOfCoincidence = emails.filter(e => e.email === email).length;

      if (countOfCoincidence > 1) {
        return errors.emails[index] = { error: 'Email has already exist' };
      }
    })
  }

  if(telegram_nicknames) {
    telegram_nicknames.forEach((currentNickname, index) => {
      const { nickname } = currentNickname;

      if(!nickname) {
        return errors.telegram_nicknames[index] = { error: 'Enter the nickname'}
      }

      const countOfCoincidence = telegram_nicknames.filter(e => e.nickname === nickname).length;

      if (countOfCoincidence > 1) {
        return errors.telegram_nicknames[index] = { error: 'Nickname has already exist' };
      }
    })
  }


  if (urls) {
    urls.forEach((currentUrl, index) => {
      const { url } = currentUrl;

      if (getIsFieldEmpty(url)) {
        errors.urls[index] = {
          ...errors.urls[index],
          url: 'Enter the link',
        };
      }
    })
  }

  if (!fn) {
    errors.fn = 'Name is required';
  }

  return errors;
};

const mapStateToProps = (state, ownProps) => ({
  getErrors: state.errors,
  agents: selectAgentsList(state),
  defaultAgent: selectAgentContactById(state, ownProps.contact),
  divaProfile: state.divaGirls.entities[selectContactById(state, ownProps.contact.id)?.diva_default_id],
});

const mapDispatchToProps = {
  updateContact,
  removeContact,
  addNewContact,
  removeAgentData,
  addAgentData,
  updateAgentsGirlIds,
  closeModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactEditForm);
