import React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { selectContactById } from 'redux/selectors/selectors';
import { updateActiveContact } from 'redux/ducks/clientChats';

import './ProfileCardContacts.scss';
import CallButton from 'components/CallButton/CallButton';
import ICONS from 'assets/icons';

const ProfileCardContacts = ({ contact, ...props }) => {
  let navigate = useNavigate();

  const openContactChat = () => {
    navigate('/');
    props.updateActiveContact(contact);
  }

  return (
    <div className="profile-card-contacts">
      <div className="profile-card-contacts__btns">
        <button
          className="profile-card-contacts__btn profile-card-contacts__btn--mess"
          onClick={openContactChat}
          disabled={!contact}
        >
          <ICONS.comments width={20} height={20} />
        </button>

        <CallButton
          width={20}
          height={20}
          className="profile-card-contacts__btn"
          activeRecipient={contact}
        />
      </div>

      {contact && (!!contact.tels.length || !!contact.emails.length) && (
        <div className="profile-card-contacts__info">
          {!!contact.tels.length && (
            <div className="profile-card-contacts__tels">
              {contact.tels.map((tel) => (
                <div
                  key={tel.id}
                  className="profile-card-contacts__tel"
                >
                  {tel.tel}
                </div>
              ))}
            </div>
          )}

          {!!contact.emails.length && (
            <div className="profile-card-contacts__emails">
              {contact.emails.map((email) => (
                <div
                  key={email.id}
                  className="profile-card-contacts__email"
                >
                  {email.email}
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state, ownProps) => ({
  contact: selectContactById(state, ownProps.contactId)
});

const mapDispatchToProps = {
  updateActiveContact
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileCardContacts);