import { CONTACT_TYPES } from 'config/constants';
import { getContactsById } from './contacts';
import { getActiveDivaGirls } from './divaGirls';

export const DEFAULT_ZOOM = 14;

export const MAP_MODES = {
  ALL_ESCORTS: 'all-escorts',
  CHAT_ESCORTS: 'chat-escorts',
  NAVIGATION: 'navigation',
}

export const MAP_GIRL_FILTERS = {
  CHAT_ESCORTS: 'chat-escorts',
  FINDED_ESCORT: 'finded-escort',
  AVAILABLE_ESCORTS: 'available-escorts',
}

export const CLIENT_INITIAL_OBJECT = { longitude: null, latitude: null, radius: { value: 0 } };
export const CENTER_COORDINATES = { lat: 51.50208118987632, lng: -0.16862733974063682 };

const UPDATE_ZOOM = 'UPDATE_ZOOM';
const UPDATE_CENTER = 'UPDATE_CENTER';
const UPDATE_GIRLS_COORDINATES_DATA = 'UPDATE_GIRLS_COORDINATES_DATA';
const UPDATE_GIRL_COORDINATES_WITH_EXTRA_INFROMATION = 'UPDATE_GIRL_COORDINATES_WITH_EXTRA_INFROMATION';
const UPDATE_CLIENT_DATA = 'UPDATE_CLIENT_DATA';
const UPDATE_MODE = 'UPDATE_MODE';
const UPDATE_LOCATION = 'UPDATE_LOCATION';
const UPDATE_FILTER = 'UPDATE_FILTER';
const UPDATE_FILTER_GIRL_IDS = 'UPDATE_FILTER_GIRL_IDS;'
const RESET_MAP_STATE = 'RESET_MAP_STATE';

export const updateZoom = (zoom) => ({
  type: UPDATE_ZOOM,
  payload: { zoom },
});

export const updateCenter = (center) => ({
  type: UPDATE_CENTER,
  payload: { center },
});

export const updateGirlsCoordinatesData = (data) => ({
  type: UPDATE_GIRLS_COORDINATES_DATA,
  payload: {
    girls: data.data,
    radiusProfiles: data.radiusProfiles,
  }
})

export const updateGirlCoordinateWithProfile = (profileId) => (dispatch) => {
  const config = {
    params: { ['filter-ids']: profileId},
    quickBooking: true,
    isGetFromState: true,
  }

  dispatch(getActiveDivaGirls(config))
    .then(({ girls }) => dispatch({
      type: UPDATE_GIRL_COORDINATES_WITH_EXTRA_INFROMATION,
      payload: {
        profileId,
        data: { 'profile': girls[0] },
      }
    }))
}

export const updateGirlCoordinateWithContact = (profileId, callerId) => (dispatch) => {
  dispatch(getContactsById(callerId, CONTACT_TYPES.GIRL, false, false, true))
    .then((contact) => dispatch({
      type: UPDATE_GIRL_COORDINATES_WITH_EXTRA_INFROMATION,
      payload: {
        profileId,
        data: { contact }
      }
    }))
}

export const updateClientCoordinates = (place) => ({
  type: UPDATE_CLIENT_DATA,
  payload: {
    longitude: Number(place.longitude) || place.geometry.location.lng(),
    latitude: Number(place.latitude) || place.geometry.location.lat(),
  },
})

export const updateClientRadius = (radius) => ({
  type: UPDATE_CLIENT_DATA,
  payload: { radius },
})

export const resetClientData = () => ({
  type: UPDATE_CLIENT_DATA,
  payload: CLIENT_INITIAL_OBJECT,
})

export const updateMode = (mode, modeProps = {}) => ({
  type: UPDATE_MODE,
  payload: { mode, modeProps },
})

export const updateLocation = (location) => ({
  type: UPDATE_LOCATION,
  payload: { location },
})

export const resetLocation = () => ({
  type: UPDATE_LOCATION,
  payload: { location: '' },
})

export const updateFilter = (filter = null) => ({
  type: UPDATE_FILTER,
  payload: { filter },
})

export const updateActiveGirlIds = (ids) => ({
  type: UPDATE_FILTER_GIRL_IDS,
  payload: { [MAP_GIRL_FILTERS.CHAT_ESCORTS]: ids }
})

export const updateFindedGirlId = (id) => ({
  type: UPDATE_FILTER_GIRL_IDS,
  payload: { [MAP_GIRL_FILTERS.FINDED_ESCORT]: [id] }
})

export const resetFindedGirlId = () => ({
  type: UPDATE_FILTER_GIRL_IDS,
  payload: { [MAP_GIRL_FILTERS.FINDED_ESCORT]: null }
});

export const resetMapState = () => ({ type: RESET_MAP_STATE })

const initialState = {
  zoom: DEFAULT_ZOOM,
  center: CENTER_COORDINATES,
  girlsData: {},
  clientData: CLIENT_INITIAL_OBJECT,
  mode: MAP_MODES.CHAT_ESCORTS,
  modeProps: {},
  location: '',
  filter: MAP_GIRL_FILTERS.CHAT_ESCORTS,
  filteredGirlIdsByFilters: {},
  updated: false,
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case UPDATE_ZOOM:
      return {
        ...state,
        zoom: payload.zoom,
        updated: true,
      }
    case UPDATE_CENTER:
      return {
        ...state,
        center: payload.center,
        updated: true,
      }
    case UPDATE_GIRLS_COORDINATES_DATA:
      const availableGirlIds = [...state.filteredGirlIdsByFilters[MAP_GIRL_FILTERS.AVAILABLE_ESCORTS] || []];
      const { girls, radiusProfiles } = payload;
      const newUpdateGirlsData = {};

      girls.forEach(girl => {
        if (girl.available_status) {
          availableGirlIds.push(girl.profile_id);
        }

        newUpdateGirlsData[girl.profile_id] = { ...state.girlsData[girl.profile_id], girl };
      });

      radiusProfiles?.forEach(profile => {
        newUpdateGirlsData[profile.id] = { ...state.girlsData[profile.id], profile };
      });

      return {
        ...state,
        girlsData: { ...state.girlsData, ...newUpdateGirlsData },
        filteredGirlIdsByFilters: {
          ...state.filteredGirlIdsByFilters,
          [MAP_GIRL_FILTERS.AVAILABLE_ESCORTS]: availableGirlIds,
        },
        updated: true,
      }
    case UPDATE_GIRL_COORDINATES_WITH_EXTRA_INFROMATION:
      const { profileId, data } = payload;

      return {
        ...state,
        girlsData: {
          ...state.girlsData,
          [profileId]: {
            ...state.girlsData[profileId],
            ...data,
          }
        },
        updated: true,
      }

    case UPDATE_CLIENT_DATA:
      return {
        ...state,
        clientData: {
          ...state.clientData,
          ...payload,
        },
        updated: true,
      }
    
    case UPDATE_MODE:
      return {
        ...state,
        mode: payload.mode,
        modeProps: payload.modeProps,
        updated: true,
      }

    case UPDATE_LOCATION:
      return {
        ...state,
        location: payload.location,
        updated: true,
      }

    case UPDATE_FILTER:
      return {
        ...state,
        filter: state.filter === payload.filter ? null : payload.filter,
        updated: true,
      }

    case UPDATE_FILTER_GIRL_IDS:
      return {
        ...state,
        filteredGirlIdsByFilters: {
          ...state.filteredGirlIdsByFilters,
          ...payload,
        },
        updated: true,
      }

    case RESET_MAP_STATE: {
      return initialState;
    }

    default:
      return state;
  }
}

