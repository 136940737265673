import { useDispatch, useSelector } from 'react-redux';

import ICONS from 'assets/icons';
import { openModal, MODAL_TYPES } from 'redux/ducks/activeWindows';
import { selectIsMapStateSaved } from 'redux/selectors/selectors';

import './HeaderModalButtons.scss';
import HeaderModalBookingBtn from './components/HeaderModalBookingBtn';
import HeaderModalTasksBtn from './components/HeaderModalTasksBtn';
import { classModifier } from 'utils';

const HeaderModalButtons = () => {
  const isMapStateSaved = useSelector(selectIsMapStateSaved);
  const dispatch = useDispatch();

  return (
    <div className="header-modal-btns">
      <HeaderModalBookingBtn />

      <button
        className="header-modal-btns__button"
        onClick={() => dispatch(openModal(MODAL_TYPES.girlsMap))}
      >
        <ICONS.map
          className={classModifier("header-modal-btns__icon", isMapStateSaved && 'active' )}
          width="20px"
          height="20px"
        />
      </button>

      <HeaderModalTasksBtn />

      <button
        className="header-modal-btns__button"
        onClick={() => dispatch(openModal(MODAL_TYPES.multiMessageModal))}
      >
        <ICONS.msgArrows className="header-modal-btns__icon" width="20px" height="20px" />
      </button>

      <button className="header-modal-btns__button">
        <ICONS.robot className="header-modal-btns__icon" width="20px" height="20px" />
      </button>
    </div>
  )
}

export default HeaderModalButtons;
