import React, { memo, useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { useOpenCommModuleModal, usePrevious, useSetSocketPageStatus } from 'hooks';
import { useFilteredBookings } from 'components/FilteredBookings';

import './CalendarPage.scss';
import CalendarHeader from '../../containers/CalendarHeader/CalendarHeader';
import CalendarTwoDays from 'containers/CalendarMain/components/CalendarTwoDays/CalendarTwoDays';
import CalendarTimeline from 'containers/CalendarMain/components/CalendarTimeline/CalendarTimeline';
import CalendarSplit from 'containers/CalendarMain/components/CalendarSplit/CalendarSplit';
import CalendarWeekView from 'containers/CalendarMain/components/CalendarWeekView/CalendarWeekView';
import CalendarTable from 'containers/CalendarMain/components/CalendarTable/CalendarTable';
import { SOCKET_PAGES_TITLES } from 'config/constants';

const getFilteredBookingConfig = (location) => {
  if (location.pathname === '/calendar/week') {
    return {
      boundaryDays: 7,
      separatedBookingsByDay: true,
      limit: 20,
    }
  }

  if (location.pathname === '/calendar/two-days') {
    return {
      boundaryDays: 2,
      separatedBookingsByDay: true,
      separatedFiltersByDay: true,
      limit: 30,
    }
  }

  return {
    limit: 30,
  }
}

const CalendarPage = () => {
  const location = useLocation();
  const previousLocation = usePrevious(location);

  const filteredBokingsConfig = {
    memoizedFilters: 5,
    isAutoScrollToActualTime: true,
    ...getFilteredBookingConfig(location),
  }
  const { resetFilters, ...bookingProps } = useFilteredBookings(filteredBokingsConfig);
  const { date } = bookingProps;

  const [selectedBookings, setSelectedBookings] = useState({
    all: false,
    exceptIds: [],
    includeIds: [],
  });

  useOpenCommModuleModal()
  useSetSocketPageStatus(SOCKET_PAGES_TITLES.calendar)

  useEffect(() => {
    if (location.pathname !== '/calendar' && previousLocation.pathname !== '/calendar') {
      resetFilters();
    }
  }, [location.pathname]);

  return (
    <div className="calendar-page">
      <CalendarHeader
        getters={bookingProps.getters}
        loadMore={bookingProps.loadFilteredBookings}
        date={date.flat()}
        getFilterProps={bookingProps.getFilterProps}
        selectedBookings={selectedBookings}
      />

      <div className="calendar-page__main">
        <Routes>
          <Route
            path="/two-days"
            element={<CalendarTwoDays {...bookingProps} />}
          />

          <Route
            path="/timeline"
            element={<CalendarTimeline {...bookingProps} />}
          />

          <Route
            path="/calendar"
            element={<CalendarSplit {...bookingProps} />}
          />

          <Route
            path="/week"
            element={<CalendarWeekView {...bookingProps} />}
          />

          <Route
            path="/table"
            element={<CalendarTable {...bookingProps} selectedBookings={selectedBookings} setSelectedBookings={setSelectedBookings} />}
          />

          <Route
            path="/*"
            element={<Navigate to={`../timeline`} replace />}
          />
        </Routes>
      </div>
    </div>
  );
};

export default memo(CalendarPage);
