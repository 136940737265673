import { classModifier, getContactAvatar } from 'utils';

import { useMemo, useState } from 'react';
import Marker from '../../../../../components/UI/Marker/Marker';
import GirlsMarkerTooltip from './GirlsMarkerTooltip';
import LazyLoadImage from 'components/LazyLoadImage/LazyLoadImage';


const GirlsMapCustomMarker = (props) => {
  const {
    lat,
    lng,
    girlInformation,
    tooltip: Tooltip,
    clientPosition,
    navigationModeProps,
    saveMapState,
    ...restProps
  } = props;

  const [shouldCloseTooltip, setShouldCloseTooltip] = useState(false);

  const { available_status } = girlInformation;
  const classes = classModifier('girl-marker', available_status && 'available');

  const girlMarkerTooltip = useMemo(() => (
    <GirlsMarkerTooltip
      girlInformation={girlInformation}
      clientPosition={clientPosition}
      navigationModeProps={navigationModeProps}
      setShouldCloseTooltip={setShouldCloseTooltip}
      saveMapState={saveMapState}
    />
  ), [clientPosition]);

  return (
    <Marker
      lat={lat}
      lng={lng}
      className={classes}
      tooltip={girlMarkerTooltip}
      extraTooltipCondition={!shouldCloseTooltip}
      {...restProps}
    >
      <LazyLoadImage
        alt="photo"
        src={getContactAvatar(girlInformation)}
        className="girls-map__marker-img"
      />
    </Marker>
  );
};

export default GirlsMapCustomMarker;
