import React from 'react'
import classNames from 'classnames';
import { useDispatch } from 'react-redux';

import { classModifier } from 'utils';
import { MODAL_TYPES, openModal, playMedia } from 'redux/ducks/activeWindows';
import { IMAGE_FORMAT_REGEX } from 'config/constants';

import LazyLoadImage from 'components/LazyLoadImage/LazyLoadImage';
import VideoThumbnail from 'components/VideoThumbnail/VideoThumbnail';
import AudioPlayer from 'components/AudioPlayer/AudioPlayer';
import MediaWaves from '../../ChatMessageInput/components/MediaWaves/MediaWaves';


const previewItem = {
  image: LazyLoadImage,
  video: VideoThumbnail,
}

const PinnedMsgAttachments = ({ attachments, ...props }) => {
  const {
    images = [],
    videos = [],
    voiceMsg,
  } = attachments;

  const arrayVideos = props.isMenuOpen ? videos : videos.slice(0, 6);
  const arrayImages = props.isMenuOpen ? images : images.slice(0, 6 - (videos.length));
  const media = [...arrayVideos, ...arrayImages];

  const dispatch = useDispatch();

  if (!voiceMsg && !media.length) {
    return null
  }

  const classes = classNames(
    props.className,
    classModifier('pinned-msgs-attachments', [
      !props.isMenuOpen && media.length === 2 && 'mod2',
      !props.isMenuOpen && media.length > 2 && media.length <= 4 && 'mod4',
      !props.isMenuOpen && media.length > 4 && 'mod6',
      props.isMenuOpen && 'open',
      props.isMenuOpen && media.length === 1 && 'modopen1',
    ])
  )

  const handleClickMedia = (event, index) => {
    event.stopPropagation();

    const modalProps = {
      media,
      defaultActiveSlide: index,
      isNotProfile: true,
    }

    dispatch(openModal(MODAL_TYPES.profileCarousel, modalProps))
  }

  if (voiceMsg?.duration) {
    return (
      <AudioPlayer
        src={voiceMsg.url}
        blobDuration={voiceMsg.duration * 1000}
        mimeType={voiceMsg.blob ? voiceMsg.blob?.type : null}
        onPlay={() => dispatch(playMedia())}
        isMinimized={!props.isMenuOpen}
        hideTimePosition
        hideVolumeControl
        isModernControl
      >
        <MediaWaves stopped />
      </AudioPlayer>
    )
  }

  return (
    <div className={classes}>
      {media.map((item, idx) => {
        const type = IMAGE_FORMAT_REGEX.test(item.url) ? 'image' : 'video';
        const PreviewItem = previewItem[type];

        return (
          <div
            key={idx}
            className="pinned-msgs-attachments__item"
            onClick={(event) => handleClickMedia(event, idx)}
          >
            <div className="pinned-msgs-attachments__image-wrapper">
              <PreviewItem
                className={`pinned-msgs-attachments__${type}`}
                spinnerSize={40}
                src={item.url}
                alt={type}
              />
            </div>

            {props.isMenuOpen && item.fileName &&
              <p className="pinned-msgs-attachments__image-name">
                {item.fileName}
              </p>
            }
          </div>
        )
      })}
    </div>
  )
}

export default React.memo(PinnedMsgAttachments);
