import classNames from 'classnames';
import Select from 'components/UI/Select/Select';
import { SELECT_THEMES } from 'config/constants';
import { useState, useEffect } from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';

const GirlsMapModalSelectFilter = (props) => {
  const {
    name,
    request,
    isDisabled,
    requiredField,
    requiredFilter,
    selectedFilters,
    updateSelectedFilter,
    clearSelectedFilters,
  } = props;

  const [key, setKey] = useState(`${name}-${name}`);

  const additionalDataFromRequiredFilter = selectedFilters[requiredFilter];

  useEffect(() => {
    const selectedRequiredFilter = additionalDataFromRequiredFilter
      ? additionalDataFromRequiredFilter[requiredField]
      : name;

    name !== 'country' && setKey(`${name}-${selectedRequiredFilter}`);  // Change key for re-render select options
  }, [additionalDataFromRequiredFilter]);

  const loadOptions = async (inputValue, loadedOptions, { page }) => {
    if (additionalDataFromRequiredFilter || !requiredFilter) {
      const config = {
        name: inputValue,
        offset: page * 20,
        [requiredFilter]: !!requiredFilter && additionalDataFromRequiredFilter[requiredField],
      };

      const { data } = await request(config);

      const options = data.map((item) => ({
          ...item,
          label: item[name],
          value: item[name],
        })
      );

      return {
        options: options,
        hasMore: options.length >= 20,
        additional: {
          page: page + 1
        }
      };
    } else {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: 0,
        }
      };
    }
  };

  const handleChange = (selectedOption) => {
    if (name === 'country' && !selectedOption) {
      clearSelectedFilters();
      return;
    }

    if (name === 'city' && !selectedOption) {
      updateSelectedFilter('location', selectedOption);
    }

    updateSelectedFilter(name, selectedOption);
  };

  return (
    <div className="girls-map__filter-wrap">
      <label
        className='girls-map__filter-label'
        htmlFor={name}
      >
        {name}
      </label>

      <Select
        key={key}
        name={name}
        type={SELECT_THEMES.LIGHT}
        className={classNames('react-select', 'girls-map__filter')}
        // classNamePrefix='react-select'
        isClearable
        placeholder={name}
        inputId={name}
        loadingMessage={() => null}
        loadOptions={loadOptions}
        onChange={handleChange}
        isSearchable
        isDisabled={isDisabled}
        additional={{
          page: 0
        }}
      />
    </div>
  );
};

export default GirlsMapModalSelectFilter;
