import React, { useRef, useMemo, useEffect, useCallback } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import API from 'api/api';
import ICONS from 'assets/icons';
import { useToggle } from 'hooks';
import { getContactAvatar, classModifier } from 'utils';
import { deletePinnedMessage } from 'redux/ducks/clientChats';
import { CHAT_TYPES, CONTACTS_LIMIT } from 'config/constants';
import { MESSAGE_DATE_CONFIG } from 'config/dates-сonfig';

import MsgText from './MsgText';
import InteractionAttachments from '../../ChatAttachments/InteractionAttachments';
import ToggleSwitch from 'components/ToggleSwitch/ToggleSwitch';
import ContactInteractionMenu from '../InteractionMenu/ContactInteractionMenu';
import RoomInteractionMenu from '../InteractionMenu/RoomInteractionMenu';
import AttachmentRepliedMessage from '../../ChatAttachments/AttachmentRepliedMessage';
import { getContactsById } from 'redux/ducks/contacts';
import QuickBookingGirlsListItemByDiva from '../../QuickBookingGirlsListItem/QuickBookingGirlsListItemByDiva';
import { MODAL_TYPES, openModal } from 'redux/ducks/activeWindows';
import DateTime from 'components/DateTime';
import { selectActiveSessionByContactId } from '../../../../../redux/selectors/selectors';
import { getActiveDivaGirls } from 'redux/ducks/divaGirls';

const IncomingMessage = ({ interaction, activeRecipient, search, isWebmasterMsg, contactSessionId, ...props }) => {
  const [menu, toggleMenu] = useToggle(false);
  const msgRef = useRef();
  const [isAnimate, setIsAnimate] = useToggle(false);

  const dispatch = useDispatch();
  const isMsgAlreadyPinned = useSelector(
    (state) =>
      activeRecipient?.type === CHAT_TYPES.CLIENT
        ? state.clientChats.pinnedMsgs.find(el => el.id === interaction.id)
        : state.girlChats.pinnedMsgs.find(el => el.id === interaction.id)
  );

  const remindsMsgsIds = useSelector(state => [...state.clientChats.remindersMsgsIds, ...state.girlChats.remindersMsgsIds]);
  const hasReminder = remindsMsgsIds.includes(interaction.id);
  const isFromTelegram = interaction.type === 28;
  const isFromWhatsapp = interaction.type === 18 || interaction.messager === 'whatsapp';
  const isFromIphone = interaction.type === 20 || interaction.type === 22 || interaction.messager === 'iphone';
  const isFromTelegramP2P = interaction.type === 26 || interaction.messager === 'telegram_client';
  const isRoleAdmin = interaction.chat?.chatType === 'general' && interaction.sender?.authAssignment?.item_name === 'admin';

  const isContextMsg = interaction.id === +props.contextMsgId; // couse props.contextMsgId is string

  useEffect(() => {
    if (isContextMsg) {
      setIsAnimate();

      setTimeout(() => setIsAnimate(false), 2000);
    }
  }, [isContextMsg]);

  const searchByGirlName = useCallback((e) => {
    const initialQuery = e.currentTarget.getAttribute('data-value');

    const fetchData = async ({ query, cancelToken }) => {
      return await props.getActiveDivaGirls({
        params: {
          'filter-is_available_now': query ? 0 : 1,
          'search': query,
          cancelToken,
        },
        quickBooking: true,
      })
        .then((res) => {
          if (res.girls) {
            return ({
              newItems: res.girls,
              newHasMore: res.girls.length === CONTACTS_LIMIT,
            });
          }
        })
        .catch(console.error);
    }

    props.openModal(MODAL_TYPES.searchList, {
      fetchData,
      initialQuery,
      itemComponent: QuickBookingGirlsListItemByDiva,
      itemProps: {
        activeRecipient,
        contactSessionId: contactSessionId,
      },
      isHandleClickCanBeRedifinedByItem: true,
    });
  }, [contactSessionId]);

  const getMsgName = () => {
    if (props.isGlobalSearch) {
      return interaction.contact?.short_name || interaction.contact?.fn;
    }
    if (props.contextMsgId && interaction.caller) {
      return getContactAvatar(interaction.caller);
    }
    else if (['girls', 'webmaster', 'bookings'].includes(activeRecipient.id)) {
      return activeRecipient.short_name || activeRecipient.fn;
    }
    return activeRecipient.short_name
      || activeRecipient.fn
      || (interaction.sender && interaction.sender.username)
      || (activeRecipient.chatTitle === "Operators"
        ? "Network Provider"
        : activeRecipient.chatTitle);
  };

  const isUnread = () => !interaction.hasOwnProperty('isRead')
    ? false
    : !interaction.isRead && 'unread';

  const getMsgContext = (e) => {
    // if click on interaction menu
    if (e.target.closest(".ii-menu")) {
      return;
    }
    // if click on parsed girl name
    else if (e.target.closest(".interaction__clickable-name")) {
      return;
    }
    // if isWebMasterMsg => getMsgContext in GirlsChats
    if (isWebmasterMsg) {
      props.getMessageContext(interaction.id, activeRecipient)
    }

    // if we not in search return
    if (!search) return;

    // if we already in msgContext return
    if (!props.contextMsgId) {
      return e.target.className !== 'lazy-load__image'
        ? props.isGlobalSearch
          ? dispatch(getContactsById(interaction.caller_id, interaction.contact.type))
            .then((contact) => {
              props.getMessageContext(interaction.id, contact, search);
            })
          : props.getMessageContext(interaction.id, null, search)
        : null;
    }
  }

  const setContextRef = ref => {
    if (isContextMsg) {
      return props.contextMsgRef.current = ref;
    }
  }

  const items = useMemo(() => [

    {
      component: (props) =>
        <ToggleSwitch
          {...props}
          // isChecked={girl.isAvailable}
          onChange={() => API.completeWebmasterTask(interaction.webMasterTask.id)
          }
        />,
      tooltipOnHover: 'Complete',
    },

  ], [])

  const showInteractionMenu = () => {
    if (interaction.is_deleted || interaction.deleted) return null;

    // if (isWebmasterMsg) {
    //   return <InteractionMenu
    //     isOpen={menu}
    //     menuItems={items}
    //     closeMenu={toggleMenu} />
    // }

    return interaction.chatId
      ? <RoomInteractionMenu
        isOpen={menu}
        closeMenu={toggleMenu}
        interaction={interaction}
        shareMsg={props.shareMsg}
        search={search}
      />

      : <ContactInteractionMenu
        isOpen={menu}
        closeMenu={toggleMenu}
        interaction={interaction}
        search={search}
        replyMsg={props.replyMsg}
        userTimezone={props.userTimezone}
        activeRecipientType={activeRecipient.type}
        activeChannel={activeRecipient.default_channel}
        isWebmasterMsg={isWebmasterMsg}
      />
  }
  // FIXME: rename 
  // const isGotFromNotDefaultNumber = interaction.channel && (getDefaultField(activeRecipient.tels, 'tel') !== interaction.channel);

  return (
    <>
      <li
        className={classModifier('interaction', [
          'inc',
          interaction.isNew && 'new',
          isWebmasterMsg && 'webmaster',
          (interaction.is_deleted || interaction.deleted) && 'deleted',
          isUnread(),
          isRoleAdmin && 'is-role-admin'
        ])}
        // style={isContextMsg ? { border: "1px solid #0092F2" } : null}
        onClick={getMsgContext}
        ref={setContextRef}
        data-isanimate={isAnimate}
        // if we will use below {() => toggleMenu()} it will rerender this interaction
        onMouseEnter={() => toggleMenu(true)}
        onMouseLeave={() => toggleMenu(false)}
      >
        <header ref={msgRef} className={classModifier("interaction__header", !interaction.body && 'is-empty-body')}>
          <div className='interaction__icon-social-box'>
            {isFromTelegram &&
              <ICONS.robot className={classModifier('interaction__icon-social', 'telegram')} />
            }

            {isFromWhatsapp &&
              <ICONS.whatsapp className={classModifier('interaction__icon-social', 'whatsapp')} />
            }

            {isFromIphone &&
              <ICONS.apple className={classModifier('interaction__icon-social', 'iphone')} />
            }

            {isFromTelegramP2P &&
              <ICONS.telegram className={classModifier('interaction__icon-social', 'telegram')} />
            }
          </div>

          <div className={classModifier("interaction__header-info", "inc")}>
            <div className="interaction__name">{getMsgName()}</div>

            <div className="interaction__header-right">
              <div className="interaction__float">
                {hasReminder &&
                  <ICONS.bellFill className="interaction__icon-bell" />
                }

                {(interaction.is_deleted || interaction.deleted) && (
                  <span className={classModifier('interaction__status', 'deleted')}>
                    Removed
                  </span>
                )}

                {showInteractionMenu()}

                {isWebmasterMsg && <ICONS.w className='interaction__webmaster-icon' />}

                {isMsgAlreadyPinned &&
                  <button
                    className="interaction__btn-pinned"
                    onClick={() => dispatch(deletePinnedMessage(isMsgAlreadyPinned.id, activeRecipient.type))}
                  >
                    <ICONS.pin className="interaction__icon-pinned" />
                  </button>
                }
              </div>

              <DateTime
                className={classModifier('interaction__time', 'msg')}
                date={interaction.dateCreated}
                config={MESSAGE_DATE_CONFIG}
              />
            </div>
          </div>

        </header>

        <div
          className={classModifier('interaction__body-wrap', [
            (interaction.is_deleted || interaction.deleted) && 'deleted',
            (interaction.attachments?.images && interaction.body) && 'attachments',
            isRoleAdmin && 'is-role-admin',
            !interaction.body && !interaction.attachments && 'empty-body'
          ])}
          onClick={props.handleLinkClick}
        >
          {(menu && isFromIphone) &&
            <span className="interaction__msg-type">
                    {interaction.type === 20 ? 'sms' : 'imsg'}
                  </span>
          }

          {interaction.attachments?.repliedMessage &&
            <div className="interaction__replied-msg">
              <AttachmentRepliedMessage
                profileId={props.profileId}
                userHour12={props.userHour12}
                interaction={interaction.attachments.repliedMessage}
                userTimezone={props.userTimezone}
                activeRecipient={activeRecipient}
                getMessageContext={props.getMessageContext}
              />
            </div>
          }

          {(interaction.body || !interaction.attachments) &&
            <div className={classModifier("interaction__text-wrap", [
              interaction.attachments?.images && 'attachments'
            ])}>
              <MsgText
                highlightedText={search}
                interaction={interaction}
                onGirlNameClick={searchByGirlName}
                isAttachmentsExist={Boolean(interaction.attachments)}
              />
            </div>
          }

          {interaction.attachments &&
            <InteractionAttachments
              attachments={interaction.attachments}
              profileId={props.profileId}
              userTimezone={props.userTimezone}
              userHour12={props.userHour12}
            />
          }
        </div>
      </li>

      {/* {parsedGirlsList.isOpen &&
        <ParsedGirlList
          msgRef={msgRef}
          activeRecipient={activeRecipient}
          addNewArrayGirlsToState={props.addNewArrayGirlsToState}
          profileId={props.profileId}
          parsedGirlsList={parsedGirlsList}
          updateActiveContact={props.updateActiveContact}
          setParsedGirlsList={setParsedGirlsList}
        />
      } */}
    </>
  )
};

const mSTP = (state, ownProps) => ({
  contactSessionId: selectActiveSessionByContactId(state, ownProps.activeRecipient.id) || 0,
});

const mDTP = { openModal, getActiveDivaGirls };

export default connect(mSTP, mDTP)(React.memo(IncomingMessage));
