import React, { useEffect, useLayoutEffect } from 'react';
import Loadable from 'react-loadable';
import { usePopperTooltip } from 'react-popper-tooltip';

import ICONS from 'assets/icons';

import AsyncComponentLoader from 'components/AsyncComponentLoader/AsyncComponentLoader';
import { classModifier } from 'utils';

export const LoadEmojiPicker = Loadable({
  loader: () => import(/* webpackChunkName: "EmojiPickerChunk"*/ './EmojiPicker'),
  loading: AsyncComponentLoader,
  delay: 400,
  timeout: 10000, // 10 seconds
  modules: ['EmojiPickerChunk']
});

const EmojiPickerBtn = ({
  onSelect,
  isShow,
  onClick,
}) => {
  const {
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    tooltipRef,
    visible,
  } = usePopperTooltip({
    trigger: "click",
    placement: 'top',
    // mutationObserverOptions: null,
  })

  useLayoutEffect(() => {
    if (tooltipRef) {
      tooltipRef.style.zIndex = 2;
    }

    return () => {
      if (tooltipRef) {
        onClick();
      }
    }
  }, [tooltipRef])

  return <>
    <button
      className="chat-input__btn"
      ref={setTriggerRef}
      onMouseOver={LoadEmojiPicker.preload}
      onClick={onClick}
    >
      <ICONS.smile className={classModifier("chat-input__btn-icon", isShow && 'active')} />
    </button>

    {visible && isShow &&
      <div ref={setTooltipRef} {...getTooltipProps()} >
        <LoadEmojiPicker
          style={{ position: 'initial' }}
          showPreview={false}
          onSelect={onSelect}
          native={true}
        />
      </div>
    }
  </>
}


export default EmojiPickerBtn;
