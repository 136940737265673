import { BOOKING_DETAILS_ITEMS_NAMES } from 'components/BookingItemNew/config/constants';
import { PROFILE_BOOKING_DATE_CONFIG } from 'config/dates-сonfig';

import './BookingGirlItem.scss';
import { classModifier } from '../../../../utils';

const DETAILS_ITEMS = [
  BOOKING_DETAILS_ITEMS_NAMES.DURATION,
  BOOKING_DETAILS_ITEMS_NAMES.TYPE,
  BOOKING_DETAILS_ITEMS_NAMES.RATE,
]

const BookingGirlItem = (props) => {
  const {
    Client,
    ConfirmationStatus,
    Markers,
    Operator,
    Requirements,
    Date,
    Details,
    isDeleted,
  } = props;

  return (
    <div
      className={classModifier('booking-girl-item', isDeleted && 'deleted')}
    >
      <div className="booking-girl-item__row">
        <Client
          className="booking-girl-item__client"
          isAvatarShowed
        />

        <div className="booking-girl-item__graphs">
          <div className="booking-girl-item__graphs-row">
            <Requirements />
            <ConfirmationStatus />
          </div>

          <Markers className="booking-girl-item__markers" />
        </div>
      </div>

      <div className="booking-girl-item__row">
        <Date className="booking-girl-item__date" config={PROFILE_BOOKING_DATE_CONFIG} />
        <Details items={DETAILS_ITEMS} />
        <Operator />
      </div>
    </div>
  )
}

export default BookingGirlItem;

