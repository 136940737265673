import { BOOKING_DETAILS_ITEMS_NAMES } from 'components/BookingItemNew/config/constants';
import { BOOKING_ITEM_BY_FORM_CONFIG } from 'config/dates-сonfig';

import './ExistedBookingItem.scss';
import classNames from 'classnames';

const DETAILS_ITEMS = [BOOKING_DETAILS_ITEMS_NAMES.DATE];


const ExistedBookingItem = (props) => {
  const {
    className,
    Escort,
    Details,
    handleOpenBookingModal,
    isDeleted,
  } = props;

  return (
    <div
      className={classNames("booking-form-item", [className, isDeleted && 'deleted'])}
      onClick={handleOpenBookingModal}
    >
      <Escort
        classPrefix="booking-form-item-escort"
        isAvatarShowed
        namesAlignment="gorizontal"
        isNotClickable
        extraText={
          <Details
            className="booking-form-item__details"
            items={DETAILS_ITEMS}
            dateConfig={BOOKING_ITEM_BY_FORM_CONFIG}
          />
        }
      />
    </div>
  )
};

export default ExistedBookingItem;
