import React, { Fragment, memo } from "react";

import ICONS from "assets/icons";

const ProfileCardInfo = (props) => {
  const {
    sameParams,
    address,
  } = props;

  const getRealAge = (birthday) => {
    if (birthday) {
      const b = birthday.split('-');
      const birthdayDate = new Date(b[0], b[1] - 1, b[2]);
      return `(${Math.floor((Date.now() - birthdayDate.getTime()) / 1000 / 60 / 60 / 24 / 365)})`;
    }
  }

  const renderParamClassName = (isActive) => isActive
    ? 'profile-card__value profile-card__same-param'
    : 'profile-card__value';

  return (
    <Fragment>
      <div className="profile-card__info-wrap">
        <div className="profile-card__info">
          <div className="profile-card__key">Age (on site):</div>
          <div className={renderParamClassName(!!sameParams.age)}>{props.age} y.o.</div>
        </div>
        {props.birthday && 
          <div className="profile-card__info">
            <div className="profile-card__key">Age (real):</div>
            <div className="profile-card__value">{`${props.birthday} ${getRealAge(props.birthday)}`}</div>
          </div>
        }   
        <div className="profile-card__info">
          <div className="profile-card__key">Hair</div>
          <div className={renderParamClassName(!!sameParams.hair)}>{props.hair}</div>
        </div>
        <div className="profile-card__info">
          <div className="profile-card__key">Height</div>
          <div className={renderParamClassName(!!sameParams.height)}>{props.height}</div>
        </div>
        <div className="profile-card__info">
          <div className="profile-card__key">Breast</div>
          <div className={renderParamClassName(!!sameParams.breast_size)}>{props.breast_size}</div>
        </div>
        <div className="profile-card__info">
          <div className="profile-card__key">Dress</div>
          <div className={renderParamClassName(!!sameParams.dress)}>{props.dress}</div>
        </div>

        <br />

        <div className="profile-card__info">
          <div className="profile-card__key">Nationality</div>
          <div className={renderParamClassName(!!sameParams.nationality)}>{props.nationality}</div>
        </div>

        <div className="profile-card__info">
          <div className="profile-card__key">Languages</div>

          <div className="profile-card__value">
            {props.languages && props.languages.map(lang =>
              <div
                key={lang}
                className={sameParams.language && sameParams.language.includes(lang) ? 'profile-card__same-param' : ''}
              >
                {lang};
              </div>
            )}
          </div>
        </div>

        <br />

        <div className="profile-card__info">
          <div className="profile-card__key">Location</div>
          <div className="profile-card__value">
            {props.location && props.location.map(loc =>
              <div
                key={loc}
                className={sameParams.location && sameParams.location.includes(loc) ? 'profile-card__same-param' : ''}
              >
                {loc};
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="profile-card__location">
        <ICONS.mapDash className="profile-card__location-icon" />

        <a
          className="profile-card__location-link"
          href={`https://www.google.com/maps/search/?api=1&query=${address.latitude},${address.longitude}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {address.place}
        </a>
      </div>
    </Fragment>
  );
};

export default memo(ProfileCardInfo);