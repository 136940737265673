import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Highlighter from 'react-highlight-words';
import { usePopperTooltip } from 'react-popper-tooltip';

import ICONS from 'assets/icons';
import { getContactAvatar, classModifier } from 'utils';
import {
  selectActiveFolderTab,
  selectActiveSessionByContactId,
  selectDefaultSession,
  selectOperatorById,
  selectUserTimezone
} from 'redux/selectors/selectors';
import {
  LIST_TYPES,
  MAIL_LIST_LIMIT,
  setIsMailStarred,
  setMailListPending,
  deleteMailsToTrash,
  toggleIsMailSelected,
  deleteMailsPermanently,
  deleteMailsFromSearchList,
} from 'redux/ducks/mail';
import { createSession } from 'redux/ducks/sessions';
import { MAIL_ITEM_DATE_CONFIG } from 'config/dates-сonfig';
import {  openModal, closeModal } from 'redux/ducks/activeWindows';
import useClickAway from 'hooks/useClickAway';
import { useToggle } from 'hooks';
import popperViewportCenter from 'config/popperViewportCenter';
import API from 'api/api';
import { MODAL_TYPES } from 'redux/ducks/activeWindows';
import { CARD_TABS } from 'config/constants';
import fetchSearchClientsContacts from 'utils/fetchSearchClientsContacts';
import useSocket from 'hooks/useSocket';

import './MailListItem.scss';
import LazyLoadImage from 'components/LazyLoadImage/LazyLoadImage';
import Spinner from 'components/UI/Spinner/Spinner';
import FloatingMenu from 'components/UI/FloatingMenu/FloatingMenu';
import DateTime from 'components/DateTime';
import NotesForm from 'components/NotesForm/NotesForm';
import DateTimePicker from 'components/DateTimePicker/DateTimePicker';
import avatarDefaultImg from 'assets/images/avatars/avatarDefault.png';
import SearchListContactItem from 'components/SearchList/components/SearchListContactItem/SearchListContactItem';
import Portal from 'components/Portal';

const MailListItem = (props) => {
  const {
    item,
    type,
    offset,
    search,
    sortBy,
    activeFolderTab,
    setIsMailStarred,
    deleteMailsToTrash,
    setMailListPending,
    toggleIsMailSelected,
    deleteMailsPermanently,
    selectedConversationsIds,
    deleteMailsFromSearchList,
    defaultSession,
    createSession,
    isSalesPageOpen,
    contactSessionId,
    operator,
  } = props;

  const navigate = useNavigate();

  const popupRef = useRef();
  const [pending, setPending] = useState({ delPending: false, starredPending: false });
  const [isNotesPopupOpened, toggleIsNotesPopupOpened] = useToggle(false);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [newRemind, setNewRemind] = useState(item.reminders?.length && item.reminders[0]);

  const isSent = type === LIST_TYPES.sent;
  const isDrafts = type === LIST_TYPES.drafts;
  const isSpam = type === LIST_TYPES.spam;
  const isTrash = type === LIST_TYPES.trash;
  const isSearch = type === LIST_TYPES.search;
  const isOutgoingLastMsg = item.user_id || isSent || isDrafts;
  const { draft } = item;

  const itemCallerId = item.caller_id || item.caller?.id;
  const itemConversationId = item.conversationId || item.conversation_id;
  const itemDraft = item.draft_uuid || item.isDraft;
  const itemCallerFn = item.caller_fn || item.caller?.fn;
  const isToPrebooking = item.to === 'prebooking@divaescort.com';

  const somePending = Object.values(pending).includes(true);
  const isPermanentlyDelete = isSpam || isTrash || draft;
  const isMailSelected = selectedConversationsIds.includes(itemConversationId);

  useClickAway(popupRef, toggleIsNotesPopupOpened);

  const [isLoading, setIsLoading] = useState(false);
  const [callerName, setCallerName] = useState(item.caller_fn);
  const [isTemporary, setIsTemporary] = useState(item.is_temporary);

  const calendarTooltip = usePopperTooltip({
    trigger: 'click',
    visible: isCalendarOpen,
    onVisibleChange: setIsCalendarOpen,
  }, {
    modifiers: [popperViewportCenter],
  });

  const getDeletionConfig = () => {
    const config = {};

    if (isSearch) {
      config.search = search;
    } else {
      config.folder = type;
      config.mode = activeFolderTab;
      config.sortBy = sortBy;
    }

    config.offset = offset;
    config.limit = MAIL_LIST_LIMIT;
    config.conversationsIds = [itemConversationId];

    return config;
  };

  const handleClick = () => {
    if (somePending) {
      return;
    }

    // If only one draft is present in conversation and nothing else
    if (draft) {
      const { id, to, subject, body, attachments } = draft;

      return navigate("../compose", {
        state: {
          draft: { id, email: to, subject, message: body, attachments },
        },
      });
    }

    // If conversation contains messages other than draft
    navigate(`./email/${itemConversationId}`);
  }

  const handleSelect = (e) => {
    e.stopPropagation();

    toggleIsMailSelected(itemConversationId);
  }

  const handleDelete = async (e) => {
    e.stopPropagation();

    setPending(prev => ({ ...prev, delPending: true }));
    setMailListPending(true);

    if (isSearch) {
      await deleteMailsFromSearchList(getDeletionConfig());
    } else if (isPermanentlyDelete) {
      await deleteMailsPermanently(getDeletionConfig());
    } else {
      await deleteMailsToTrash(getDeletionConfig());
    }

    setPending(prev => ({ ...prev, delPending: false }));
    setMailListPending(false);
  }

  const handleStarred = async (e) => {
    e.stopPropagation();

    setPending(prev => ({ ...prev, starredPending: true }));
    setMailListPending(true);

    await setIsMailStarred({
      folder: type,
      conversationId: itemConversationId,
      isStarred: !Number(item.is_starred),
      activeMailTab: activeFolderTab,
    });

    setPending(prev => ({ ...prev, starredPending: false }));
    setMailListPending(false);
  }

  const toggleSession = (e) => {
    e.stopPropagation();

    // If we don`t have caller_id ( we can get this if click Operator Drafts)
    if (!itemCallerId) {
      return null
    }

    if (contactSessionId) {
      API.switchSalesSession(contactSessionId);
      return null;
    }

    // Get new session
    if (props.activeSession === 0) {
      createSession(
        itemCallerId,
        [],
        defaultSession.comparedIds,
        defaultSession.activeFilters,
        defaultSession.additionalFilters,
        defaultSession.recentlyViewedIds,
      )
    }
    // If the caller_id already had a session
    else {
      createSession(itemCallerId);
    }

    if (!isSalesPageOpen) {
      window.open('/sales', '_blank');
    }
  }

  const handleNewDate = (timestamp) => {
    API.createMailRemind({
      remind_at: timestamp,
      caller_id: itemCallerId,
      conversationId: itemConversationId
    })
      .then(({ data }) => {
        setIsCalendarOpen(false)
        setNewRemind(data)
      })
  };

  async function getContactById(caller_id) {
    try {
      const { data } = await API.getContactsById(caller_id);
      return data[0];
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  const handleEdit = async (event) => {
    event.stopPropagation();

    try {
      const contact = await getContactById(item.caller_id);

      props.openModal(MODAL_TYPES.contactCard, {
        contact,
        defaultTab: CARD_TABS.EDIT,
        autoFocusInput: 'fn',
      });

    } catch (error) {
      console.error(error);
    }
  };

  const onAddClient = () => {
    props.openModal(MODAL_TYPES.searchList, {
      fetchData: fetchSearchClientsContacts,
      itemComponent: SearchListContactItem,
      onChoose: async (selectedContact) => {
        setIsLoading(true)

        try {
          const donorContact = await getContactById(item.caller_id);

          const preparedContact = {
            ...selectedContact,
            tels: [
              ...selectedContact.tels,
              ...donorContact.tels,
            ],
            emails: [
              ...selectedContact.emails,
              ...donorContact.emails,
            ]
          }

          await API.mergeContactCards(preparedContact, item.caller_id)

          props.closeModal(MODAL_TYPES.searchList);

          props.openModal(MODAL_TYPES.contactCard, {
            contact: preparedContact,
            defaultTab: CARD_TABS.EDIT,
          });

          setCallerName(preparedContact.fn);
          setIsTemporary(false);
        } catch (error) {
          console.error(error)
        }
        setIsLoading(false)
      },
    })
  }

  const addNewContactItems = [
    {
      text: 'Add new contact',
      action: handleEdit,
    },
    {
      text: 'Add to existing contact',
      action: onAddClient,
    },
  ]

  useSocket((event) => {
    const { data, type } = JSON.parse(event.data);

    if (type === 'contacts_sync' && data.type === 'update') {
      if (data.contact.id == item.caller_id) {
        setCallerName(data.contact.fn);
        setIsTemporary(false);
      }
    }
  })

  return (
    <li
      className={classModifier('mail-list-item', [
        !Number(item.viewed) && 'unread',
        somePending && 'pending',
        !!Number(item.is_starred) && 'starred',
      ])}
    >
      {itemDraft &&
        <ICONS.pencil className="mail-list-item__draft-label" />
      }

      <button
        className="mail-list-item__checkbox-btn"
        onClick={handleSelect}
        disabled={somePending}
      >
        <div
          className={classModifier('mail-list-item__checkbox',
            isMailSelected && 'active'
          )}
        >
          {isMailSelected &&
            <ICONS.check className="mail-list-item__checkbox-check-icon" />
          }
        </div>
      </button>

      <button
        className={classModifier(
          "mail-list-item__notes",
          !Number(item.is_notes) && "empty"
        )}
        onClick={toggleIsNotesPopupOpened}
      >
        <ICONS.penSquare />
      </button>

      <button
        className={classModifier("mail-list-item__remainder", !newRemind && 'empty')}
        onClick={() => {
          if (newRemind) {
            API.removeMessageReminder(newRemind.id)
              .then(() => setNewRemind(null))
          }
        }}
        ref={!newRemind && calendarTooltip.setTriggerRef || null}
      >
        <ICONS.bellFill />
      </button>
      {isCalendarOpen && (
        <div
          ref={calendarTooltip.setTooltipRef}
          {...calendarTooltip.getTooltipProps({
            className: 'calendar-form-field__tooltip',
          })}
        >
          <DateTimePicker
            onSelectDate={handleNewDate}
            onCancel={() => setIsCalendarOpen(false)}
          />
        </div>
      )}

      {isNotesPopupOpened && (
        <NotesForm
          conversationId={itemConversationId}
          toggleIsNotesPopupOpened={toggleIsNotesPopupOpened}
          innerRef={popupRef}
        />
      )}

      <div className="mail-list-item__avatar">
        <LazyLoadImage src={item.caller_photo || item.caller?.photo?.src || avatarDefaultImg} />
      </div>

      <div
        className="mail-list-item__preview"
        onClick={handleClick}
      >
        <div className="mail-list-item__preview-header">
          <p className="mail-list-item__name">
            <Highlighter
              highlightClassName='mail-list-item__highlight'
              searchWords={[search]}
              autoEscape={true}
              textToHighlight={callerName || (isOutgoingLastMsg ? item.to : item.from) || 'NO RECEPIENT'}
            />
          </p>

          {isOutgoingLastMsg && !isDrafts &&
            <p className="mail-list-item__username">
              <Highlighter
                highlightClassName='mail-list-item__highlight'
                searchWords={[search]}
                autoEscape={true}
                textToHighlight={operator?.username || 'Fastmail'}
              />
            </p>
          }

          {isToPrebooking && <ICONS.p className="mail-list-item__prebooking-icon"/>}

          {item.count > 1 &&
            <p className="mail-list-item__count">{item.count}</p>
          }

          {!!Number(item.is_attachments) || item.attachments?.length &&
            <ICONS.clip className="mail-list-item__attachments-icon" />
          }

          <p className="mail-list-item__subject">
            <Highlighter
              highlightClassName='mail-list-item__highlight'
              searchWords={[search]}
              autoEscape={true}
              textToHighlight={item.subject || 'NO TITLE'}
            />
          </p>

          {item.folder_type &&
            <p className='mail-list-item__folder'>
              {item.folder_type.toLowerCase()}
            </p>
          }
        </div>

        <p className="mail-list-item__message">
          <Highlighter
            highlightClassName='mail-list-item__highlight'
            searchWords={[search]}
            autoEscape={true}
            textToHighlight={item.body}
          />
        </p>
      </div>

      <div className={classModifier("mail-list-item__actions-wrapper", Number(isTemporary) && 'extended')}>
        <div className="mail-list-item__actions">
          <DateTime
            className="mail-list-item__date"
            date={item.created_at}
            config={MAIL_ITEM_DATE_CONFIG}
          />

          <button
            className="mail-list-item__action-btn"
            disabled={somePending}
            onClick={handleStarred}
          >
            <span
              className="mail-list-item__mark-sign"
              title={!!Number(item.is_starred) ? 'Unmark' : 'Mark'}
            />
          </button>

          <button
            className="mail-list-item__action-btn"
            title={isPermanentlyDelete ? "Delete Permanently" : "Delete (to Trash)"}
            disabled={somePending}
            onClick={handleDelete}
          >
            {pending.delPending
              ? <Spinner spinnerSize={24} />
              : <ICONS.trash />
            }
          </button>

          {!!Number(isTemporary) && <FloatingMenu
            items={addNewContactItems}
            trigger='click'
          >
            <button
              className="mail-list-item__action-btn"
              title='Create or merge contact'
            >
              <ICONS.bookPlus />
            </button>
          </FloatingMenu>}

          <button
            className="mail-list-item__action-btn"
            onClick={toggleSession}
            title={contactSessionId ? 'switch session' : 'start session'}
          >
            <ICONS.puzzle />
          </button>
        </div>
      </div>

      {isLoading && (
          <Portal>
            <div className="mail-list-item__spinner">
              <Spinner spinnerSize="32px" />
            </div>
          </Portal>
        )
      }
    </li>
  );
};

const mapStateToProps = (state, ownProps) => ({
  offset: state.mail.offset,
  search: state.mail.mailList.search,
  userHour12: state.user.hour12,
  sortBy: state.mail.mailList.activeSortFilter,
  activeFolderTab: selectActiveFolderTab(state),
  userTimezone: selectUserTimezone(state),
  selectedConversationsIds: state.mail.selectedConversationsIds,
  activeSession: state.sessions.activeSession,
  defaultSession: selectDefaultSession(state),
  isSalesPageOpen: state.user.isSalesPageOpen,
  contactSessionId: selectActiveSessionByContactId(state, ownProps.item.caller_id || ownProps.item.caller?.id) || 0,
  operator: selectOperatorById(state, ownProps.item.user_id, true),
});

const mapDispatchToProps = {
  deleteMailsPermanently,
  deleteMailsToTrash,
  toggleIsMailSelected,
  setIsMailStarred,
  setMailListPending,
  deleteMailsFromSearchList,
  createSession,
  openModal,
  closeModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(MailListItem));
