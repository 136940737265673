import classNames from 'classnames';
import _ from 'lodash';
import { v4 } from 'uuid';

import API from 'api/api'
import { classModifier } from 'utils';
import { useDispatch, useSelector } from 'react-redux';
import { getContactsById } from 'redux/ducks/contacts';
import { MODAL_TYPES, openModal } from 'redux/ducks/activeWindows';
import { DIVA_GIRLS_IMG_HOST } from 'redux/ducks/divaGirls';
import girlImg from 'assets/images/avatars/girl.png';
import setDeviderBetweenJSX from 'utils/setDeviderBetweenJSX';
import { updateActiveContact } from 'redux/ducks/clientChats';

import "./BookingEscort.scss";
import Tooltip from 'components/UI/Tooltip/Tooltip';

const EscortName = (props) => {
  const {
    name,
    id,
    isNotClickable,
    textStyle,
    shouldOpenChat,
    extraAfterClickAction,
    availability
  } = props;

  const dispatch = useDispatch();

  const openChat = (callerId) => {
    dispatch(getContactsById(callerId))
      .then((contact) => {
        if (contact) {
          dispatch(updateActiveContact(contact))
        }
      })

    const customEvent = new CustomEvent('openChat', { detail: { girlId: callerId } });
    dispatchEvent(customEvent);
  }

  const openContactModal = (id) => {
    dispatch(getContactsById(id))
      .then((contact) => {
        if (contact) {
          dispatch(openModal(MODAL_TYPES.contactCard, { contact: contact.id }));
        }
      })
  }

  const handleClick = (event, id) => {
    event.stopPropagation();

    if (isNotClickable) {
      return;
    }

    API.getCallerIdByProfileDivaId(id)
      .then(({ data: { id: contactId } }) => {
        if (contactId) {
          shouldOpenChat ? openChat(contactId) : openContactModal(contactId);
        }
      }).catch(console.error);

    extraAfterClickAction?.({ girlId : id });
  }

  return (
    <Tooltip text={name} visibleWhenIsOverflowed>
      <div
        className={classModifier("booking-escort__name", availability?.split(' ')?.join('-'))}
        style={textStyle}
        onClick={(event) => handleClick(event, id)}
      >
        {name || '-'}
      </div>
    </Tooltip>
  )
}

const EscortAvatar = ({ link }) => (
  <img
    alt="ava"
    className={classModifier('booking-escort__avatar')}
    src={link || girlImg}
  />
)

const renderNames = (names, namesAlignment, extraText) => {
  const classes = classModifier(
    "booking-escort__names",
    namesAlignment === 'reverse-vertical' && 'reversed',
  )
  const namesWithDevider = setDeviderBetweenJSX(names, '/')

  const renderedNames = namesAlignment === 'gorizontal' || extraText
    ? (
      <div className="booking-escort__row-names">
        {namesWithDevider}
      </div>
    )
    : names

  return (
    <div className={classes}>
      {renderedNames}
      {extraText && (
        <div className="booking-escort__extra-text">
          {extraText}
        </div>
      )}
    </div>
  )
}


const BookingEscort = (props) => {
  const {
    classPrefix,
    booking,
    isAvatarShowed,
    hideAvatarsWhenDuoBookingExist,
    namesAlignment = 'gorizontal',
    extraText = '',
    isNotClickable = false,
    style = {},
    textStyle = {},
    shouldOpenChat = false,
    extraAfterClickAction
  } = props;

  const girlProfiles = useSelector((state) => state.divaGirls.entities);

  const soloId = booking.profile_id;
  // const duoId = booking?.group_girls
  //   ? Object.keys(booking.group_girls).find(id => Number(id) !== Number(booking.profile_id))
  //   : booking.profile_id_duo
  const duoId = booking.profile_id_duo;

  const createNameAndAvatarComponents = () => {
    const {
      group_girls,
      profile_pictures,
      profile_name,
      availability,
      date_created
    } = booking;

    const nameProps = { isNotClickable, textStyle, shouldOpenChat, extraAfterClickAction }

    const createObject = (id, isShowedAvatar) => {
      const girl = girlProfiles[id];
      const girlAvatarLink = profile_pictures?.[id] || (girl?.avatar ? DIVA_GIRLS_IMG_HOST + girl?.avatar : null);
      const girlName = girl?.name || group_girls?.[id] || profile_name;

      return {
        name: <EscortName  key={v4()} id={id} name={girlName} availability={availability?.[id]} {...nameProps} />,
        ...(isShowedAvatar ? { avatar: <EscortAvatar key={v4()} link={girlAvatarLink} /> } : {})
      }
    }

    return [soloId, duoId].filter(Boolean).reduce((acc, id, _, arr) => {
      const isShowedAvatar = arr.length > 1
        ? isAvatarShowed && !hideAvatarsWhenDuoBookingExist
        : isAvatarShowed

      return [createObject(id, isShowedAvatar), ...acc]
    }, [])
  }

  const content = createNameAndAvatarComponents()

  const avatars = content.map(({ avatar }) => avatar);
  const names = content.map(({ name }) => name);

  return (
    <div
      className={classNames('booking-escort', classPrefix && classPrefix + "__container")}
      style={{...style, cursor: isNotClickable ? 'default' : 'pointer'}}
    >
      {content.length === 1 && !avatars[0]
        ? names[0]
        : (
          <div className={classNames("booking-escort__content", classPrefix && classPrefix + "__content")}>
            <div className={classNames("booking-escort__avatar-container", classPrefix && classPrefix + "__avatars-container")}>
              {avatars}
            </div>
            {renderNames(names, namesAlignment, extraText)}
          </div>
        )
      }
    </div>
  )
}

export default BookingEscort;
