import { useEffect, useState } from 'react';

import API from 'api/api';
import GirlsMapModalSelectFilter from './GirlsMapModalSelectFilter';

const FILTERS_LIST_DATA = [
  { name: 'country',
    request: API.getCountriesOptionsForGirlsMap,
    requiredFilter: null,
  },
  {
    name: 'city',
    request: API.getCitiesOptionsForGirlsMap,
    requiredFilter: 'country',
    requiredField: 'country',
  },
  {
    name: 'location',
    request: API.getLocationsOptionsForGirlsMap,
    requiredFilter: 'city',
    requiredField: 'city',
  },
];

const GirlsMapModalFilters = ({ flyTo, getGirlsCoordinatesByBounds }) => {
  const [selectedFilters, setSelectedFilters] = useState({
    country: null,
    city: null,
    location: null
  });

  useEffect(() => {
    const { country, city, location } = selectedFilters;
    let coordinates = null;
    let zoom = 12;
    
    getGirlsCoordinatesByBounds(true);

    if (location) {
      coordinates = { lat: parseFloat(location.latitude), lng: parseFloat(location.longitude) };
      zoom = 14;
    } else if (city) {
      coordinates = { lat: parseFloat(city.latitude), lng: parseFloat(city.longitude) };
      zoom = 11;
    } else if (country) {
      coordinates = { lat: parseFloat(country.latitude), lng: parseFloat(country.longitude) };
      zoom = 6;
    }

    if (!!coordinates) {
      flyTo(coordinates, zoom);
    }
  }, [selectedFilters]);

  const updateSelectedFilter = (name, value) => {
    setSelectedFilters((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const clearSelectedFilters = () => {
    setSelectedFilters({ country: null, city: null, location: null });
  };

  return (
    <div className="girls-map__filters">
      {FILTERS_LIST_DATA.map((filter) => (
        <GirlsMapModalSelectFilter
          key={filter.name}
          name={filter.name}
          request={filter.request}
          selectedFilters={selectedFilters}
          requiredField={filter.requiredField}
          requiredFilter={filter.requiredFilter}
          updateSelectedFilter={updateSelectedFilter}
          clearSelectedFilters={clearSelectedFilters}
          isDisabled={!!filter.requiredFilter && !selectedFilters[filter.requiredFilter]}
        />
      ))}
    </div>
  )
};

export default GirlsMapModalFilters;
