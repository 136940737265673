import React, { useEffect, useMemo, useState } from 'react';
import Select from 'react-select';
import { Field } from 'react-final-form';
import { connect } from 'react-redux';
import { usePopperTooltip } from 'react-popper-tooltip';
import _ from 'lodash';

import { 
  CONTACT_TYPES, 
  CLIENT_CATEGORY_OPTIONS, 
  CLIENT_SUBTYPE_OPTIONS 
} from 'config/constants';
import ICONS from 'assets/icons';
import { classModifier } from 'utils';
import { selectAgentsList } from 'redux/selectors/selectors';
import { CONTACT_INFO_DATE_CONFIG } from 'config/dates-сonfig';

import ContactAvatar from '../ContactAvatar';
import Spinner from 'components/UI/Spinner/Spinner';
import AdrBookProfilesPopupList from '../AdrBookProfilesPopupList';
import FormTextInput from 'components/FormTextInput/FormTextInput';
import ViaItem from 'components/ContactFormItems/ViaItems/components/ViaItem/ViaItem';
import CopyToClipboardButton from 'components/CopyToClipboardButton/CopyToClipboardButton';
import DateTime from 'components/DateTime';

const CLIENT_TYPE_OPTIONS = [
  { label: 'Client', value: CONTACT_TYPES.CLIENT },
  { label: 'Girl', value: CONTACT_TYPES.GIRL },
  { label: 'Agent', value: CONTACT_TYPES.AGENT },
  { label: 'Services', value: CONTACT_TYPES.SERVICES },
  { label: 'Reception', value: CONTACT_TYPES.RECEPTION },
  { label: 'Network Provider', value: CONTACT_TYPES.NETWORK_PROVIDER },
]

const AdrContactFormHeader = props => {
  const {
    photo,
    active,
    agents,
    values,
    setPhoto,
    setValues,
    saveContact,
    serverError,
    editedContact,
    setEditedContact,
    onChangeFullName,
    isNewContactCreation,
   } = props;

  const [isShowPopup, setIsShowPopup] = useState(false);
  const [isPendingPopupItems, setIsPendingPopupItems] = useState(false);
  const [subtypes, setSubtypes] = useState(CLIENT_SUBTYPE_OPTIONS);
  
  useEffect(() => {
    if(values.category) {
      const updatedSybtypesList = CLIENT_SUBTYPE_OPTIONS.filter(option => option.value === 0);
      setSubtypes(updatedSybtypesList);
      setValues('subtype', 0);
    } else {
      setSubtypes(CLIENT_SUBTYPE_OPTIONS);
    }

    if(+values.subtype !== 0) {
      setValues('category', null)
    }
  }, [values.category, values.subtype]);  

  const {
    visible,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef
  } = usePopperTooltip({
    trigger: 'click',
    visible: isShowPopup,
    placement: 'bottom-start',
    mutationObserverOptions: null,
    onVisibleChange: setIsShowPopup,
  });

  const isGirlType = +values.type === +CONTACT_TYPES.GIRL;
  const isAgentType = +values.type === +CONTACT_TYPES.AGENT;
  const isClientType = +values.type === +CONTACT_TYPES.CLIENT;
  const isReceptionType = +values.type === +CONTACT_TYPES.RECEPTION;

  const idFromFn = values?.custom_id;
  const isContactWithAgent = isGirlType || isReceptionType;
  const isForPaymentContactType = isGirlType || isAgentType;
  const isEditedContactType = !isNewContactCreation && (!!values.type && editedContact?.type !== +values.type);
  const isEditedAgent = isContactWithAgent && !isNewContactCreation && (editedContact.agentId !== values?.agentId);
  // const isEditedEx = isContactWithAgent && !isNewContactCreation && (editedContact.is_ex !== values?.is_ex);
  const isEditedClientCategory = !isNewContactCreation && (editedContact?.category !== values.category);
  const isEditedClientSubtype = !isNewContactCreation && (editedContact?.subtype !== values.subtype);
  const errorMsgToString = typeof serverError === 'object' 
    ? !_.isEmpty(serverError) ? JSON.stringify(serverError) : ''
    : serverError;
  const isDisabledSubtypeSelect = subtypes.length === 1;

  const agentsOptions = useMemo(() => [
    { label: '(no agent)', value: null, color: '#808080' },
    ...agents.map(agent => ({ label: agent.fn, value: agent.id }))
  ], [agents]);

  const selectAgentStyles = useMemo(() => ({
    singleValue: (provided, { data }) => ({
      ...provided,
      color: `${data.color} !important`,
    })
  }), []);

  const deleteContactAvatar = (values) => {
    if(window.confirm("Delete avatar?")) {
      setPhoto({ url: null, file: null, src: null });
      saveContact({ 
        ...values, 
        file: null,
        photo: { url: null, file: null, src: null }
      });
    }
  }

  // const getDate = (dateTime) => {
  //   if (!dateTime) return;

  //   const [date] = dateTime.split(" ");
  //   const [yyyy, mm = "", dd = ""] = date.split("-");

  //   return `${dd}/${mm}/${yyyy}`;
  // };

  const changeContactAvatar = (file) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      if (file.type.startsWith("image")) {
        setPhotoHandler(file);
      }
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleBlur = () => (
    isEditedContactType ||
    isEditedAgent ||
    isEditedClientCategory ||
    isEditedClientSubtype
  ) && saveContact(values);

  const setPhotoHandler = (file) => {
    setPhoto({ file, url: file ? URL.createObjectURL(file) : null});
  };

  const onChangeContactCategory = option => {
    setValues('category', option.value);
    // setEditedContact({ ...editedContact, category: option.value, isEdited: true });
    onChangeFullName({ category: option.value });
  };

  const onClientChange = (e, input) => {
    input.onChange(e);

    const { name, value } = e.target;

    onChangeFullName({ [name]: value });
  };

  // const getCategoryColor = (category) => {
  //   return CLIENT_CATEGORY_OPTIONS.find(option => option.value === category)?.color || '#E6EBF1';
  // };

  const renderIdField = () => (
    <div className={classModifier('adr-book-contact-form__input', ['id', isGirlType && 'is-contact-girl'])}>
      <span className='adr-book-contact-form__input-title'>ID:</span>
      <Field name="custom_id">
        {({ input, ...fieldProps }) => (
          <FormTextInput
            {...fieldProps}
            autoComplete="off"
            serverErrors={serverError}
            input={{ ...input, onChange: (e) => onClientChange(e, input)} }
            inputProps={{ type: "number" }}
            className={classModifier('adr-book-contact-form__input-id')}
          />
        )}
      </Field>

      {/* {values.custom_id && !isGirlType &&
        <button
          type="button"
          className="adr-book-contact-form__btn-clear"
          onClick={() => onChangeFullName({ custom_id: '' }, true)}
        >
          <ICONS.close className="adr-book-contact-form__btn-clear-icon"/>
        </button>
      } */}
    </div>
  );

  return (
    <header className="adr-book-contact-form__header">
      <div className="adr-book-contact-form__avatar-wrapper">
        <Field
          name='image'
          photo={photo}
          values={values}
          component={ContactAvatar}
          cropSubmit={setPhotoHandler}
          classPrefix="adr-contact-ava"
          editedContact={editedContact}
          isNewContactCreation={isNewContactCreation}
          onChangeContactAvatar={changeContactAvatar}
          onDeleteContactAvatar={() => deleteContactAvatar(values)}
          cropperClassPrefix="edit-contact-form-img-cropper"
          saveContact={(file) => saveContact({ ...values, file })}
        />

        <CopyToClipboardButton
          value={values.fn}
          title='Copy contact name'
        />
      </div>
      <div className="adr-book-contact-form__header-content">
        <div className="adr-book-contact-form__header-content-wrapper">
          <div className="adr-book-contact-form__fields-wrapper">
            <div
              className={classModifier('adr-book-contact-form__field', 'type')}
              onBlur={handleBlur}
            >
              Type:
              <Select
                options={CLIENT_TYPE_OPTIONS}
                isSearchable={false}
                className="react-select"
                classNamePrefix="react-select"
                defaultValue={CLIENT_TYPE_OPTIONS[0]}
                value={CLIENT_TYPE_OPTIONS.find(el => String(el.value) === values.type)}
                onChange={option => setValues('type', option.value)}
                getOptionValue={option => option.value}
              />
            </div>

            {isClientType && (
              <>
                <div
                  className={classModifier('adr-book-contact-form__field', [
                    'type', 'subtype', isDisabledSubtypeSelect && 'disabled'
                  ])}
                  onBlur={handleBlur}
                >
                  Subtype:
                  <Select
                    options={subtypes}
                    isSearchable={false}
                    className="react-select"
                    classNamePrefix="react-select"
                    defaultValue={subtypes[0]}
                    value={subtypes.find(el => el.value === values.subtype)}
                    onChange={option => setValues('subtype', option.value)}
                    getOptionValue={option => option.value}
                    isDisabled={isDisabledSubtypeSelect}
                  />
                </div>

                {values.is_trusted && 
                  <div className={classModifier('adr-book-contact-form__field', 'is-trusted')}>
                    Trusted
                  </div>
                }

                <div
                  className={classModifier('adr-book-contact-form__field', ['category', (values.subtype !== 0) && 'disabled'])}
                  onBlur={handleBlur}
                >
                  <Select
                    options={CLIENT_CATEGORY_OPTIONS}
                    isSearchable={false}
                    className="react-select"
                    classNamePrefix="react-select"
                    defaultValue={{ value: null, label: '' }}
                    value={CLIENT_CATEGORY_OPTIONS.find(el => el.value === values.category)}
                    onChange={onChangeContactCategory}
                    getOptionValue={option => option.value}
                    isDisabled={values.subtype !== 0}
                  />
                </div>
                
                {renderIdField()}
              </>
            )}

            {(isForPaymentContactType || isReceptionType) &&
              <ViaItem
                viaFieldName="is_ex"
                setValues={setValues}
                valueToChange={values.fn}
                saveContact={(field, value) => !isNewContactCreation && saveContact({ ...values, [field]: value })}
                viaTitle={<div className="adr-book-contact-form__ex">EX</div>}
              />
            }
            {isForPaymentContactType &&
              <ViaItem
                viaTitle={<ICONS.card />}
                viaFieldName="available_card"
                saveContact={(field, value) => !isNewContactCreation && saveContact({ ...values, [field]: value })}
              />
            }

            {isContactWithAgent &&
              <button
                type="button"
                className="adr-book-contact-form__checkbox-btn"
              >
                <div
                  className={classModifier('adr-book-contact-form__field', 'agent')}
                  onBlur={handleBlur}
                >
                  Agent:
                  <Select
                    options={agentsOptions}
                    className="react-select"
                    classNamePrefix="react-select"
                    defaultValue={agentsOptions[0]}
                    styles={selectAgentStyles}
                    value={agentsOptions.find(el => el.value === values.agentId)}
                    onChange={option => setValues('agentId', option.value)}
                    getOptionValue={option => option.value}
                  />
                </div>
              </button>
            }
          </div>

          {active && !isNewContactCreation && (
            <div className="adr-book-contact-form__date-status">
              {'Created: '}

              <DateTime
                date={editedContact.date_created}
                config={CONTACT_INFO_DATE_CONFIG}
              />
              
              {' ; '}

              {'Edited: '}

              <DateTime
                date={editedContact.date_updated}
                config={CONTACT_INFO_DATE_CONFIG}
              />
            </div>
          )}

          {errorMsgToString && <span className='adr-book-contact-form__error-msg'>{errorMsgToString}</span>}
        </div>

        <div className={classModifier("adr-book-contact-form__input-wrap", isGirlType && 'is-contact-girl')}>
          {(isGirlType && idFromFn) && renderIdField()}

          {isClientType
            ? (
              <div className={classModifier("adr-book-contact-form__input-wrap", "name")}>
                <span className='adr-book-contact-form__input-title'>Name:</span>
                <Field name="name">
                  {({ input, ...fieldProps }) => (
                    <FormTextInput
                      {...fieldProps}
                      autoComplete="off"
                      serverErrors={serverError}
                      input={{ ...input, onChange: (e) => {
                        onClientChange(e, input);
                        setEditedContact({ ...editedContact, fn: e.target.value, isEdited: true });
                      }}}
                      className={classModifier('adr-book-contact-form__input', 'name')}
                    />
                  )}
                </Field>

                {values.name &&
                  <button
                    type="button"
                    className="adr-book-contact-form__btn-clear"
                    onClick={() => onChangeFullName({ name: '' }, true)}
                  >
                    <ICONS.close className="adr-book-contact-form__btn-clear-icon"/>
                  </button>
                }
              </div>
            ) 
            : <>
              <div 
                ref={setTriggerRef} 
                className="adr-book-contact-form__fn-wrapper"
              >
                <Field
                  name="fn"
                  component={FormTextInput}
                  serverErrors={serverError}
                  autoComplete="off"
                  placeholder="Full Name"
                  className={classModifier('adr-book-contact-form__input', 'fn')}
                />
                {values.fn && 
                  <button 
                    type="button" 
                    className="adr-book-contact-form__btn-clear"
                    onClick={() => setValues('fn', '')}
                  >
                    <ICONS.close className="adr-book-contact-form__btn-clear-icon"/>
                  </button>
                }
              </div>
              
              {isNewContactCreation && isGirlType && 
                <>
                  {/* <div 
                    ref={setTooltipRef} 
                    className="adr-book-popup-wrapper"
                  > */}
                    <AdrBookProfilesPopupList 
                      ref={setTooltipRef}
                      setPhoto={setPhoto}
                      setIsShowSpinner={setIsPendingPopupItems}
                      isShowPopup={visible}
                      setIsShowPopup={setIsShowPopup}
                      getTooltipProps={getTooltipProps}
                    />

                  {isPendingPopupItems &&
                    <div className="adr-book-contact-form__popup-spinner">
                      <Spinner /> 
                    </div>
                  }
                </>
              }
            </>
          }

          {isClientType &&
            <div className={classModifier("adr-book-contact-form__input-wrap", "description")}>
              <span className='adr-book-contact-form__input-title'>Description:</span>
              <Field name="description">
                {({ input, ...fieldProps }) => (
                  <FormTextInput
                    {...fieldProps}
                    autoComplete="off"
                    serverErrors={serverError}
                    input={{ ...input, onChange: (e) => {
                      onClientChange(e, input);
                      setEditedContact({ ...editedContact, description: e.target.value, isEdited: true });
                    }}}
                    className={classModifier('adr-book-contact-form__input', 'description')}
                    showTooltipWhenOvercrowded
                  />
                )}
              </Field>

              {values.description &&
                <button
                  type="button"
                  className="adr-book-contact-form__btn-clear"
                  onClick={() => onChangeFullName({ description: '' }, true)}
                >
                  <ICONS.close className="adr-book-contact-form__btn-clear-icon"/>
                </button>
              }
            </div>
          }
        </div>
      </div>
    </header>
  )
}

const mapStateToProps = (state) => ({
  agents: selectAgentsList(state),
});

export default connect(mapStateToProps)(React.memo(AdrContactFormHeader));
