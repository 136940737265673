import _ from 'lodash';

export default function diffObjects(obj1, obj2) {
  return _.reduce(obj1, (result, value, key) => {
    if (_.isObject(value) && _.isObject(obj2[key])) {
      return result.concat(diffObjects(value, obj2[key]));
    }
    return _.isEqual(value, obj2[key])
      ? result
      : result.concat([key, obj2[key]]);
  }, []);
}
